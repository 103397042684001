import React from "react";
import "./ReferralModal.css";
import crossIcon from "../../../assets/images/Cross Icon.png";

const ReferralModal = ({ isVisible, closeModal }) => {
  if (!isVisible) return null;
  return (
    <div className="referral-modal">
      <div className="modal-content">
        <img
          src={crossIcon}
          alt="Close"
          className="modal-close-icon"
          onClick={closeModal}
        />
        <h2 style={{ color: "black" }}>Dear iLaqaian,</h2>
        <p>
          It’s time to expand your iLaqa! Refer our services to your friends and
          neighbors, and for every successful referral, you’ll earn a bonus
          added directly to your account. Once your total rewards reach ₹500,
          you can start redeeming them. Redeem at ₹500 and then again at ₹1000.
          Terms and conditions apply.
        </p>
      </div>
    </div>
  );
};

export default ReferralModal;
