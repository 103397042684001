import React from "react";
import "./InprogressVoucherModal.css";
import inprocessvoucherimg from "../../../assets/images/ProfileScreen/Voucher in process smile 4.svg";
import crossIcon from "../../../assets/images/Cross Icon.png";

const InprogressVoucherModal = ({ onClose }) => {
  return (
    <div className="inprogressvouchermodal-overlay">
      <div className="inprogressvouchermodal-content">
        <div className="inprogressvouchermodal-header">
          <span onClick={onClose} className="inprogressvouchermodal-close">
            <img
              src={crossIcon}
              style={{ width: "15px", height: "15px" }}
            ></img>
          </span>
        </div>
        <div className="inprogressvouchermodal-body">
          <p>
            Your voucher is being processed! We'll notify you as soon as it's
            ready.
          </p>
          <img
            src={inprocessvoucherimg}
            alt="inprocessvoucherimg"
            className="inprocessvoucherimg"
          />
        </div>
        <div>{/* <img src={}></img> */}</div>
      </div>
    </div>
  );
};

export default InprogressVoucherModal;
