import React, { useContext, useEffect, useState } from "react";

import "./LosSubServices.css";
import arrow from "../../assets/images/ArrowBAck.svg";
import { useNavigate, useParams } from "react-router-dom";

import LosSubServiceCard from "../../components/LOS/LosSubService/LosSubServiceCard";
import { AuthContext } from "../../context/AuthContext";
import { BookServiceForUser } from "../../api/api";
import { URL } from "../../env";
import { GetServiceByName } from "../../api/api";

function LosSubServices() {
  const [services, setServices] = useState([]);
  const { service, servicetype } = useParams();

  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  const sendBookingToApi = async () => {
    try {
      const response = await BookServiceForUser(
        user?.name || "Anonymous",
        user?.phone || "N/A",
        service.serviceName,
        service.description
      );

      if (!response.ok) {
        console.error("Failed to save booking to API");
      }
    } catch (error) {
      console.error("Error sending booking to API:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await GetServiceByName(service, servicetype);
      setServices(data.services[0]);
    };
    fetchData();
  }, []);

  const handleBookNow = () => {
    window.location.href = `https://wa.me/+917032512444?text=Hello iLaqa, I want to book ${services.serviceName}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await GetServiceByName(service, servicetype);
      setServices(data.services[0]);
    };
    fetchData();
  }, []);
  if (services.length < 0) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        Loading
      </div>
    );
  }
  return (
    <div className="wrapper">
      <div className="los-subservice">
        <div className="los-subservice-top">
          <button
            className="servicelist-backbtn"
            onClick={() => {
              navigate(-1);
            }}
          >
            <img src={arrow} alt="Back Arrow" />
          </button>
          <h1 className="servicelist-title los-subservice-to-h1">
            {services.serviceName}
          </h1>
        </div>
        <p className="los-subservice-top-descri">{services.description}</p>

        {services.image != null && (
          <img
            src={`${URL}/blacklist/image/${services.image}`}
            style={{ width: "100%", height: "auto", borderRadius: "20px" }}
          ></img>
        )}
        <div className="grid-for-desktop" style={{ width: "100%" }}>
          {services?.subService?.map((subservice) => {
            return (
              <LosSubServiceCard subservice={subservice}></LosSubServiceCard>
            );
          })}
        </div>

        <button className="los-book-now" onClick={handleBookNow}>
          Book Now
        </button>
      </div>
    </div>
  );
}

export default LosSubServices;