import React, { useEffect, useRef } from 'react';
import './InsufficientBalanceModal.css';
import Balanceinsufficientsmile from "../../../assets/images/ProfileScreen/Balance insufficient smile 2.svg"; 

const InsufficientBalanceModal = ({ isOpen, onClose }) => {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="insufficient-balance-modal-overlay">
      <div className="insufficient-balance-modal-container" ref={modalRef}>
        {/* Cancel button (cross) */}
        <button className="insufficient-close-btn" onClick={onClose}>&times;</button>

        <div className="insufficient-balance-modal-content">
          <p className="insufficient-balance-message">
            Oops! Your balance is insufficient for redemption. <br />
            Earn more by referring our services and boost your bonus!
          </p>
          <div className="emoji">
            <img src={Balanceinsufficientsmile} alt="emoji" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsufficientBalanceModal;
