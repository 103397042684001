import React, { useState } from "react";
import "./ReferralFormAdmin.css";
import { PostReferralDetails } from "../../api/api";
import { useNavigate } from "react-router-dom";

function ReferralFormAdmin() {
  const [formData, setFormData] = useState({
    referrerName: "",
    referrerPhone: "",
    refereePhone: "",
    serviceName: "",
    serviceCost: "",
    rewardAmount: "",
  });
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handlePhoneChange = (e) => {
    let value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 10) {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle form submission, e.g., send data to the backend
    console.log("Form submitted: ", formData);

    const response = await PostReferralDetails(
      formData.referrerName,
      formData.referrerPhone,
      formData.refereePhone,
      formData.serviceName,
      formData.serviceCost
    );
    let message = response.json();
    if (response.status === 400) {
      alert(
        `${formData.refereePhone} is existing user and already used service`
      );
    }
    setFormData({
      referrerName: "",
      referrerPhone: "",
      refereePhone: "",
      serviceName: "",
      serviceCost: "",
      rewardAmount: "",
    });
    navigate("/dashboard/referral");
  };

  return (
    <div className="referral-form-container">
      <h2 className="referral-form-title">Referral Form</h2>
      <form onSubmit={handleSubmit} className="referral-form">
        <div className="referral-form-group">
          <label htmlFor="referrerName" className="referral-form-label">
            Referrer Name
          </label>
          <input
            type="text"
            id="referrerName"
            name="referrerName"
            value={formData.referrerName}
            onChange={handleChange}
            className="referral-form-input"
            required
          />
        </div>
        <div className="referral-form-group">
          <label htmlFor="referrerPhone" className="referral-form-label">
            Referrer Phone Number
          </label>
          <input
            placeholder="+91"
            type="tel"
            id="referrerPhone"
            name="referrerPhone"
            value={formData.referrerPhone}
            onChange={handlePhoneChange}
            className="referral-form-input"
            required
          />
        </div>
        <div className="referral-form-group">
          <label htmlFor="refereePhone" className="referral-form-label">
            Referree Phone Number
          </label>
          <input
            placeholder="+91"
            type="tel"
            id="refereePhone"
            name="refereePhone"
            value={formData.refereePhone}
            onChange={handlePhoneChange}
            className="referral-form-input"
            required
          />
        </div>
        <div className="referral-form-group">
          <label htmlFor="serviceName" className="referral-form-label">
            Service Name
          </label>
          <input
            type="text"
            id="serviceName"
            name="serviceName"
            value={formData.serviceName}
            onChange={handleChange}
            className="referral-form-input"
            required
          />
        </div>
        <div className="referral-form-group">
          <label htmlFor="serviceCost" className="referral-form-label">
            Service Cost
          </label>
          <input
            type="number"
            id="serviceCost"
            name="serviceCost"
            value={formData.serviceCost}
            onChange={handleChange}
            className="referral-form-input"
            required
          />
        </div>

        <div className="referral-form-group">
          <button type="submit" className="referral-form-button">
            Submit
          </button>
          <button
            className="submit-button"
            onClick={() => {
              navigate("/dashboard/referral");
            }}
            style={{ marginTop: "20px", backgroundColor: "#ff000088" }}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}

export default ReferralFormAdmin;
