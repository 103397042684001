import React from "react";
import { FaEdit } from "react-icons/fa";
import { FaDeleteLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { DeleteCluster, GetData } from "../../../api/api";
import "./ClusterCard.css";

const ClusterCard = ({ cluster, a, areaName, setData }) => {
  const navigate = useNavigate();
  return (
    <div className="cluster-container">
      <div className="cluster-content">
        <div className="cluster-info">
          <div className="cluster-name">{cluster.name}:</div>
          <p className="cluster-area">Area: {areaName}</p>
          <p className="cluster-buildings">
            Buildings:{" "}
            {cluster.buildings.map((b) => (
              <span key={b.name}>{b.name},</span>
            ))}
          </p>
          <p className="cluster-buildings">{cluster.description}</p>
        </div>

        <div className="cluster-buttons">
          <button
            className="delete-button"
            onClick={() => {
              const Fetchdata = async () => {
                await DeleteCluster(a, cluster._id);
                const res = await GetData();
                setData(res);
              };

              let res = window.confirm(
                `Do you want to delete ${cluster.name}? ?`
              );
              if (res) {
                Fetchdata();
              }
            }}
          >
            <FaDeleteLeft />
          </button>
          <button
            className="edit-button"
            onClick={() => {
              navigate("/dashboard/homeAdmin/edit-cluster", {
                state: { cluster, a },
              });
            }}
          >
            <FaEdit />
          </button>
        </div>
      </div>
    </div>
  );
};
export default ClusterCard;
