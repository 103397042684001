import React from "react";

import { Outlet, useNavigate } from "react-router-dom";
import logo from "../assets/images/logo.png";
import cross from "../assets/images/Cross Icon.png";
import Header from "../components/Header/Header";
function AboutUsHeaderLayout() {
  const navigate = useNavigate();
  return (
    <div
      style={{
        width: "100vw",
      }}
    >
      <div className="desktop-version">
        <Header />
      </div>

      <div className="about-us-header not-for-desktop">
        <img
          src={logo}
          style={{ width: "60px", height: "60px", cursor: "pointer" }}
          onClick={() => {
            navigate("/");
          }}
        ></img>
        <img
          src={cross}
          style={{ width: "24px", height: "24px", cursor: "pointer" }}
          onClick={() => {
            navigate(-1);
          }}
        ></img>
      </div>
      <Outlet></Outlet>
    </div>
  );
}

export default AboutUsHeaderLayout;
