import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import "./ProfileForm.css";
import { GetAreaNames, GetBuildingNames, SubmitFormData } from "../../api/api";
import defaultIcon from "../../assets/images/defaultIocn.svg";
import galleryIcon from "../../assets/images/galleryIcon.svg";
import defaultGalleryIcon from "../../assets/images/defaultGalleryIcon.svg";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import cameraIcon from "../../assets/images/cameraIcon.svg";
import { URL as url } from "../../env";
const ProfileForm = () => {
  const { user } = useContext(AuthContext);
  const [fullName, setFullName] = useState(user?.fullName || "");
  const [flatNo, setFlatNo] = useState(user?.flatNumber || "");
  const [mobile, setMobile] = useState(user?.mobile || "");
  const [email, setEmail] = useState(user?.email || "");
  const [area, setArea] = useState(user?.area || "");
  const [societyName, setSocietyName] = useState(user?.society || "");
  const [areas, setAreas] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [manualAddress, setManualAddress] = useState(user?.manualAddress || "");
  const [selectedImage, setSelectedImage] = useState(null);
  const [image, setImage] = useState(null);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const openModal = () => setShowModal(true);

  useEffect(() => {
    setMobile(user?.mobile || "");
  }, [user]);

  const handleClose = () => setShowModal(false);

  const handleGalleryClick = () => {
    document.getElementById("galleryInput").click();
  };

  const handleRemoveClick = () => {
    setSelectedImage(null);
    setShowModal(false);
  };

  const handleDefaultImageClick = () => {
    setSelectedImage(defaultIcon);
    setImage(defaultIcon);
    setShowModal(false);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size > 2 * 1024 * 1024) {
      setSelectedImage(null);
    } else {
      setSelectedImage(file);
      setImage(URL.createObjectURL(file));
    }
    handleClose();
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      marginBottom: "15px",
      backgroundColor: "#eaf2fc",
      borderRadius: "10px",
      border: "none",
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "10px",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
    }),
  };

  const optionsAreas = areas.map((area) => ({
    value: area.areaId,
    label: area.areaName,
  }));

  const optionsBuildings = [
    ...buildings.map((building) => ({
      value: building.buildingId,
      label: building.name,
    })),
    { value: "other", label: "other" },
  ];

  const handleAreaChange = async (selectedOption) => {
    setArea(selectedOption.label);
    if (selectedOption.value !== "other") {
      const data = await GetBuildingNames(selectedOption.value);
      setBuildings(data);
    } else {
      setBuildings([]);
    }
    setErrors({ ...errors, area: "" });
  };

  const handleChangeBuildings = (selectedOption) => {
    setSocietyName(selectedOption.label);
    setErrors({ ...errors, societyName: "" });
  };

  const handleManualAddressChange = (e) => {
    setManualAddress(e.target.value);
    setErrors({ ...errors, manualAddress: "" });
  };

  const validateForm = () => {
    let valid = true;

    const newErrors = {
      fullName: fullName.trim() === "" ? "Full Name is required" : "",
      area: area.trim() === "" ? "Area Name is required" : "",
      societyName: societyName.trim() === "" ? "Society Name is required" : "",
      email:
        email.trim() === ""
          ? "Email is required"
          : email.trim() === "" || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
          ? "Invalid email address"
          : "",
    };
    if (societyName === "other") {
      const temp = {
        ...newErrors,
        manualAddress: manualAddress.trim() === "" ? "Address is required" : "",
      };
      setErrors(temp);
    } else if (societyName !== "other") {
      console.log("hello");
      const temp = {
        ...newErrors,
        flatnum: flatNo.trim() === "" ? "Flat Number is required" : "",
      };
      setErrors(temp);
    } else {
      setErrors(newErrors);
    }

    valid = !Object.values(newErrors).some((msg) => msg !== "");
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    const formData = new FormData();
    formData.append("userId", user._id);
    formData.append("fullName", fullName || user.fullName);
    formData.append("area", area || user.area);
    formData.append("society", societyName || user.society);
    formData.append("flatNumber", flatNo || user.flatNumber);
    formData.append("manualAddress", manualAddress || user.manualAddress);
    formData.append("email", email || user.email);

    if (selectedImage && typeof selectedImage !== "string") {
      formData.append("avatar", selectedImage);
    } else {
      formData.append("avatar", defaultIcon);
    }

    await SubmitFormData(formData);
    navigate("/profile");
  };

  useEffect(() => {
    const fetchAreas = async () => {
      const data = await GetAreaNames();
      setAreas(data);
    };
    fetchAreas();
  }, []);

  return (
    <div className="wrapper">
      <div className="profileform">
        <div className="form-container ">
          <div
            className="avatar-box-container"
            style={{ display: "flex", gap: "10px", alignItems: "flex-end" }}
            onClick={openModal}
          >
            <div className="avatar-box">
              {user?.avatar && image === null ? (
                <img
                  src={`${url}/blacklist/image/${user.avatar}`}
                  alt="Avatar"
                  className="avtar-image"
                />
              ) : image ? (
                <img src={image} alt="Avatar" className="avtar-image" />
              ) : (
                <img
                  src={defaultGalleryIcon}
                  alt="Avatar"
                  className="avtar-box-image"
                />
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <img
                src={cameraIcon}
                style={{ width: "24px", color: "#0C60CA" }}
              />
              <p
                style={{
                  fontWeight: "500",
                  fontSize: "10px",
                  color: "#0C60CA",
                }}
              >
                Add Picture
              </p>
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="fullName">
              Full Name <span className="asterisk">*</span>
            </label>
            <input
              required
              type="text"
              id="fullName"
              name="fullName"
              placeholder="Enter your full name"
              value={fullName}
              onChange={(e) => {
                setFullName(
                  e.target.value.toLowerCase().charAt(0).toUpperCase() +
                    e.target.value.toLowerCase().slice(1)
                );
                setErrors({ ...errors, fullName: "" });
              }}
            />
            {errors.fullName && (
              <p className="errormessage">{errors.fullName}</p>
            )}
          </div>

          <div className="form-group">
            <label>
              Area <span className="asterisk">*</span>
            </label>
            <Select
              name="area"
              required
              value={optionsAreas.find((option) => option.value === area)}
              onChange={handleAreaChange}
              options={optionsAreas}
              placeholder="Select Area"
              isSearchable
              defaultValue={{ label: area, value: area }}
              styles={customStyles}
            />
            {errors.area && (
              <p className="errormessage" style={{ marginTop: "-10px" }}>
                {errors.area}
              </p>
            )}
          </div>

          <div className="form-group">
            <label>
              Society Name <span className="asterisk">*</span>
            </label>
            <Select
              name="society"
              required
              value={optionsBuildings.find(
                (option) => option.value === societyName
              )}
              onChange={handleChangeBuildings}
              options={optionsBuildings}
              placeholder="Select Society Name"
              isSearchable
              defaultValue={{ label: societyName, value: societyName }}
              styles={customStyles}
            />
            {errors.societyName && (
              <p className="errormessage" style={{ marginTop: "-10px" }}>
                {errors.societyName}
              </p>
            )}
          </div>

          {societyName === "other" ? (
            <div className="form-group">
              <label htmlFor="manualAddress">
                Manual Address <span className="asterisk">*</span>
              </label>
              <input
                required
                type="text"
                id="manualAddress"
                value={manualAddress}
                onChange={handleManualAddressChange}
                placeholder="Enter your address"
              />
              {errors.manualAddress && (
                <p className="errormessage">{errors.manualAddress}</p>
              )}
            </div>
          ) : (
            <div className="form-group">
              <label htmlFor="flatNo">
                Flat Number <span className="asterisk">*</span>
              </label>
              <input
                required
                type="text"
                id="flatNo"
                placeholder="Enter your Flat no"
                value={flatNo}
                onChange={(e) => {
                  setFlatNo(e.target.value);
                  setErrors({ ...errors, flatnum: "" });
                }}
              />
              {errors.flatnum && (
                <p className="errormessage">{errors.flatnum}</p>
              )}
            </div>
          )}

          <div className="form-group">
            <label htmlFor="mobile">Mobile</label>
            <input
              required
              type="tel"
              id="mobile"
              placeholder="Enter your mobile number"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              readOnly
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">
              Email ID <span className="asterisk">*</span>
            </label>
            <input
              name="email"
              id="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value.toLowerCase());
                setErrors({ ...errors, email: "" });
              }}
            />
            {errors.email && <p className="errormessage">{errors.email}</p>}
          </div>

          <button type="submit" className="save-button" onClick={handleSubmit}>
            Save
          </button>

          {showModal && (
            <div className="bottom-modal-overlay" onClick={handleClose}>
              <div
                className="bottom-modal"
                onClick={(e) => e.stopPropagation()}
              >
                <div className="modal-row">
                  <div
                    className="modal-option"
                    onClick={handleDefaultImageClick}
                  >
                    <img src={defaultIcon} alt="No Profile Photo" />
                  </div>
                  <div className="modal-option" onClick={handleGalleryClick}>
                    <img src={galleryIcon} alt="Select from Gallery" />
                  </div>
                </div>
                <div className="remove-text" onClick={handleRemoveClick}>
                  Remove X
                </div>
              </div>
            </div>
          )}

          <input
            type="file"
            id="galleryInput"
            accept="image/*"
            style={{ display: "none" }}
            onChange={handleImageChange}
          />
          {/* <input
          type="file"
          id="cameraInput"
          accept="image/*"
          capture="environment"
          style={{ display: "none" }}
          onChange={handleImageChange}
        /> */}
        </div>
      </div>
    </div>
  );
};

export default ProfileForm;
