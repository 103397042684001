import React, { useEffect, useState } from "react";
import "./Blacklist.css";
import { DeleteVendor, GetVendorBlacklists } from "../../api/api";
import { FaDeleteLeft, FaPlus } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { URL } from "../../env";

function VendorBlacklistadmin() {
  const [blacklistedVendors, setBlacklistedVendors] = useState();

  const navigate = useNavigate();
  useEffect(() => {
    fetchVendors();
  }, []);

  const fetchVendors = async () => {
    const res = await GetVendorBlacklists();
    setBlacklistedVendors(res);
  };

  const deleteVendor = async (id) => {
    await DeleteVendor(id);
    await fetchVendors();
  };

  return (
    <div style={{ position: "relative" }}>
      <button
        className="add-cluster-button-admin"
        style={{ position: "absolute", top: "-60px", right: "5px" }}
        onClick={() => {
          navigate("/dashboard/homeAdmin/vendor-blacklist-admin/add");
        }}
      >
        <FaPlus />
        <div>Add Vendor</div>
      </button>
      {blacklistedVendors?.map((vendor) => {
        return (
          <div className="cluster-container-admin">
            <div className="cluster-content-admin">
              <div className="cluster-info-admin">
                <div className="cluster-name-admin">{vendor.vendorName}:</div>
                <img
                  src={
                    vendor.imageUrl
                      ? `${URL}/blacklist/image/${vendor.imageUrl}`
                      : ""
                  }
                  alt={vendor.vendorName}
                  style={{ width: "100px" }}
                ></img>
                <p className="cluster-area-admin">
                  Profession: {vendor.vendorProfession}
                </p>

                <p className="cluster-area-admin">
                  Mobile No.:{vendor.mobileNumber}
                </p>
                <p className="cluster-area-admin">Reason: {vendor.reason}</p>
              </div>
              <div className="cluster-buttons-admin">
                <button className="delete-button-admin">
                  <FaDeleteLeft
                    onClick={() => {
                      let res = window.confirm(
                        `Do you want to delete ${vendor.vendorName} ?`
                      );
                      if (res) {
                        deleteVendor(vendor._id);
                      }
                    }}
                  />
                </button>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default VendorBlacklistadmin;
