import React from "react";
import './VoucherModal.css';
import voucherIcon from "../../../assets/images/ProfileScreen/Vouchers icons 6.svg"; 

const VoucherModal = ({ onClose, email, voucherCode }) => {
  return (
    <div className="vouchermodal-overlay">
      <div className="vouchermodal-content">
        <div className="vouchermodal-header">
          <span onClick={onClose} className="vouchermodal-close">&times;</span>
        </div>
        <div className="vouchermodal-body">
          <p>
            We've just sent your coupon code <strong>{voucherCode}</strong> to your mail <a href={`mailto:${email}`} className="email-link">{email}</a>.
            What are you waiting for? Grab it and unlock amazing deals!
          </p>
          <img src={voucherIcon} alt="Shopping bag icon" className="shopping-bag-icon"/>
        </div>
      </div>
    </div>
  );
};

export default VoucherModal;
