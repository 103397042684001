import React from "react";
import "./VendorCard.css";
import Call from "../../assets/images/Call.png";
import { URL } from "../../env";
const VendorCard = ({ vendors, onVendorClick }) => {
  return (
    <div className="cards-container-vendor" style={{ position: "relative" }}>
      {vendors?.map((vendor) => (
        <div
          className="card-vendor"
          key={vendor.id}
          onClick={() => {
            onVendorClick(vendor);
          }}
        >
          <img
            src={
              vendor.imageUrl ? `${URL}/blacklist/image/${vendor.imageUrl}` : ""
            }
            alt="Vendor"
            className="vendor-image"
          />

          <h3 className="vendor-name">{vendor.vendorName}</h3>
          <p className="vendor-role">{vendor.vendorProfession}</p>
          <p className="vendor-contact">
            <img src={Call} alt="Call Icon" className="call-icon" />{" "}
            {vendor.mobileNumber}
          </p>
          <div>
            <div className="not-for-desktop">
              <p className="vendor-reason">
                <strong>Reason:</strong>
                {vendor.reason.substring(0, 55)}

                {vendor.reason.length > 20 && <a href="#">Read Full</a>}
              </p>{" "}
            </div>
            <div className="desktop-version ">
              <p className="vendor-reason">
                <strong>Reason:</strong>
                {vendor.reason}
              </p>{" "}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default VendorCard;
