import React, { useEffect, useState } from "react";
import "./VendorBlacklist.css";
import VendorModal from "../../components/VendorList/VendorModal";

import VendorBlackListSearchBar from "../../components/SearchBar/VendorBlackList/VendorBlackListSearchbar";
import ServiceModal from "../../components/VendorList/VendorFilterModal";

import image from "../../assets/images/EmergencyScreenImage.svg";
import filterIcon from "../../assets/images/filterIcon.svg";
import TopVendorBlackLists from "../../components/TopSectionVendorBlacklist/TopVendorBlacklist";
import VendorCard from "../../components/VendorCardSection/VendorCard";
import { GetVendorBlacklists } from "../../api/api";
import GenralLoader from "../../components/Loader/GenralLoader";

const DEFAULT_PROFESSIONS = [
  { key: "", value: "All" },
  { key: "Electrician", value: "Electricians" },
  { key: "Plumber", value: "Plumbers" },
  { key: "Carpenter", value: "Carpenters" },
];
function VendorBlacklist() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [vendorData, setVendorData] = useState([]);
  const [filteredVendors, setFilteredVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [professions, setProfessions] = useState(DEFAULT_PROFESSIONS);
  const [loading, setLoading] = useState(true);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const closeFilterModal = () => {
    setIsFilterModalOpen(false);
  };

  useEffect(() => {
    const fetchVendors = async () => {
      const data = await GetVendorBlacklists();
      let allProfession = [DEFAULT_PROFESSIONS[0]];
      let uniqueProfessions = data.map((d) => d.vendorProfession);
      uniqueProfessions = [...new Set(uniqueProfessions)];
      uniqueProfessions.forEach((p) => {
        allProfession.push({ key: p, value: `${p}s` });
      });
      setProfessions(allProfession);

      setVendorData(data);
      setLoading(false);
    };
    fetchVendors();
  }, []);

  useEffect(() => {
    setFilteredVendors(vendorData);
  }, [vendorData]);

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = vendorData.filter(
      (v) =>
        v.vendorName.toLowerCase().includes(query) ||
        (v.mobileNumber ? v.mobileNumber.toString().includes(query) : false) ||
        v.vendorProfession.toLowerCase().includes(query)
    );
    setFilteredVendors(filtered);
  };

  const handleVendorClick = (vendor) => {
    setSelectedVendor(vendor);
    setIsModalOpen(true);
  };

  const handleRoleSelect = (role) => {
    if (role === "") {
      setFilteredVendors(vendorData);
    } else {
      const filtered = vendorData.filter(
        (v) => v.vendorProfession.toLowerCase() === role.toLowerCase()
      );
      setFilteredVendors(filtered);
    }
  };

  return (
    <div className="wrapper">
      <div className="vendor-blacklist-page">
        <TopVendorBlackLists image={image} />

        {isModalOpen && (
          <VendorModal vendor={selectedVendor} closeModal={closeModal} />
        )}
        <div style={{ alignItems: "end", justifyContent: "end" }}>
          <VendorBlackListSearchBar
            searchQuery={searchQuery}
            onSearch={handleSearch}
          />
        </div>
        {isFilterModalOpen && (
          <ServiceModal
            closeModal={closeFilterModal}
            onRoleSelect={handleRoleSelect}
            professions={professions}
          />
        )}

        <div className="vendor-header">
          <h1 className="header-title">Blacklisted Vendors</h1>
          <img
            src={filterIcon}
            className="header-icon"
            onClick={() => setIsFilterModalOpen(true)}
            alt="Filter Icon"
          />
        </div>
        {loading ? (
          <GenralLoader />
        ) : (
          <>
            {filteredVendors.length > 0 ? (
              <VendorCard
                vendors={filteredVendors}
                onVendorClick={handleVendorClick}
              />
            ) : (
              <span className="no-result-text">No result found</span>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default VendorBlacklist;

//
