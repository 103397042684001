import { useLocation, useNavigate } from "react-router-dom";
import "./EditLosAdmin.css";
import React, { useState } from "react";
import { EditService } from "../../api/api";

const EditLosAdmin = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { s: clickedService, typeId } = state;
  console.log(clickedService, typeId);
  const [editedService, setEditedService] = useState({ ...clickedService });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedService((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubServiceChange = (index, field, value) => {
    const updatedSubServices = editedService.subService.map((subService, i) => {
      if (i === index) {
        return { ...subService, [field]: value };
      }
      return subService;
    });
    setEditedService((prevState) => ({
      ...prevState,
      subService: updatedSubServices,
    }));
  };

  const handleDetailChange = (subServiceIndex, detailIndex, field, value) => {
    const updatedSubServices = editedService.subService.map((subService, i) => {
      if (i === subServiceIndex) {
        const updatedDetails = subService.details.map((detail, j) => {
          if (j === detailIndex) {
            return { ...detail, [field]: value };
          }
          return detail;
        });
        return { ...subService, details: updatedDetails };
      }
      return subService;
    });

    setEditedService((prevState) => ({
      ...prevState,
      subService: updatedSubServices,
    }));
  };

  const handleAddSubService = () => {
    const newSubService = {
      name: "",
      details: [],
    };
    setEditedService((prevState) => ({
      ...prevState,
      subService: [...prevState.subService, newSubService],
    }));
  };

  const handleAddDetail = (subServiceIndex) => {
    const newDetail = {
      name: "", // Editable name
      value: "", // Editable value
    };

    const updatedSubServices = editedService.subService.map((subService, i) => {
      if (i === subServiceIndex) {
        return { ...subService, details: [...subService.details, newDetail] };
      }
      return subService;
    });

    setEditedService((prevState) => ({
      ...prevState,
      subService: updatedSubServices,
    }));
  };

  const handleDeleteSubService = (index) => {
    const updatedSubServices = editedService.subService.filter(
      (_, i) => i !== index
    );
    setEditedService((prevState) => ({
      ...prevState,
      subService: updatedSubServices,
    }));
  };

  const saveChanges = async () => {
    const filteredSubServices = editedService.subService
      .map((subService) => {
        const filteredDetails = subService.details.filter(
          (detail) => detail.name.trim() !== "" && detail.value.trim() !== ""
        );

        return {
          ...subService,
          details: filteredDetails,
        };
      })
      .filter((subService) => subService.name.trim() !== "");

    await EditService(
      typeId,
      editedService._id,
      editedService.serviceName,
      editedService.description,
      filteredSubServices
    );
    navigate("/dashboard/los");
  };

  return (
    <div className="edit-form-container">
      <h3 className="edit-form-title">
        Edit Service: {editedService.serviceName}
      </h3>

      <label className="edit-form-label">
        Service Name:
        <input
          type="text"
          name="serviceName"
          value={editedService.serviceName}
          onChange={handleInputChange}
          className="edit-form-input"
        />
      </label>

      <label className="edit-form-label">
        Description:
        <input
          type="text"
          name="description"
          value={editedService.description}
          onChange={handleInputChange}
          className="edit-form-input"
        />
      </label>

      <h4 className="subservice-title">Sub-Services</h4>
      {editedService.subService.map((subService, index) => (
        <div key={subService._id} className="subservice-container">
          <label className="edit-form-label">
            Sub-Service Name:
            <input
              type="text"
              value={subService.name}
              onChange={(e) =>
                handleSubServiceChange(index, "name", e.target.value)
              }
              className="edit-form-input"
            />
          </label>

          {subService.details.map((detail, detailIndex) => (
            <div key={detail._id} className="detail-container">
              <label className="edit-form-label">
                Detail Name:
                <input
                  type="text"
                  value={detail.name}
                  onChange={(e) =>
                    handleDetailChange(
                      index,
                      detailIndex,
                      "name",
                      e.target.value
                    )
                  }
                  className="edit-form-input"
                />
              </label>
              <label className="edit-form-label">
                Detail Value:
                <input
                  type="text"
                  value={detail.value}
                  onChange={(e) =>
                    handleDetailChange(
                      index,
                      detailIndex,
                      "value",
                      e.target.value
                    )
                  }
                  className="edit-form-input"
                />
              </label>
            </div>
          ))}
          <button
            className="add-detail-button"
            onClick={() => handleAddDetail(index)}
          >
            Add Detail
          </button>
          <button
            className="delete-subservice-button"
            onClick={() => {
              let res = window.confirm(`Do you want to delete ?`);
              if (res) {
                handleDeleteSubService(index);
              }
            }}
          >
            Delete Sub-Service
          </button>
        </div>
      ))}

      <button className="add-subservice-button" onClick={handleAddSubService}>
        Add Sub-Service
      </button>
      <button className="save-changes-button" onClick={saveChanges}>
        Save Changes
      </button>
      <button
        className="cancel-button"
        onClick={() => {
          navigate("/dashboard/los");
        }}
      >
        Cancel
      </button>
    </div>
  );
};

export default EditLosAdmin;
