import React, { useRef, useEffect } from "react";
import "./RedeemModal.css";
import WinkSmile from "../../../assets/images/ProfileScreen/Wink Smile 1.svg";
import crossIcon from "../../../assets/images/Cross Icon.png";
const RedeemModal = ({ isOpen, onClose, userName }) => {
  const modalRef = useRef(null);

  // Close modal on clicking outside of the modal
  const handleOverlayClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };

  // close the modal after 3 seconds

  if (!isOpen) return null;

  return (
    <div className="redeem-modal-overlay" onClick={handleOverlayClick}>
      <div
        className="redeem-modal-container"
        ref={modalRef}
        style={{ position: "relative" }}
      >
        <img
          src={crossIcon}
          alt="Close"
          className="email-close-icon"
          style={{ position: "absolute", top: "10px", right: "10px" }}
          onClick={onClose}
        />
        <div className="redeem-modal-content">
          <p className="redeem-para">
            Hi <strong>{userName}</strong>,<br />
            Invite your friends to join iLaqa, and for every successful
            referral, you'll earn a bonus added directly to your account. Once
            your total rewards reach ₹500, you can start redeeming them.
            Redemptions are available in multiples of ₹500, up to ₹1000 at a
            time. Terms and conditions apply.
          </p>
          <div className="emoji">
            <img src={WinkSmile} alt="winksmile" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RedeemModal;
