import React, { useState } from "react";
import { FaEdit } from "react-icons/fa";
import { FaDeleteLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import "./ShopCard.css";
// import { DeleteShop } from "../../api/api";
import ConfirmModal from "../confirmToDeleteModal/ComfirmToDeleteModal";
import { DeleteShop } from "../../api/api";

const ShopCard = ({ shop, onDelete }) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const handleDelete = async (areaId, clusterId, shopId) => {
    try {
      const response = await DeleteShop(areaId, clusterId, shopId);
      if (response.success) {
        alert("Shop deleted successfully!");
        onDelete();
      } else {
        alert("Shop deleted successfully!");
        onDelete();
      }
    } catch (error) {
      console.error("Error deleting shop:", error);
      alert("An error occurred. Please try again.");
    }
  };

  const handleConfirmDelete = () => {
    handleDelete(shop.areaId, shop.clusterId, shop.shopId);
    setShowModal(false);
  };

  const handleEditClick = (shop) => {
    navigate("/dashboard/shops/shopAdd", { state: { shop } });
  };

  return (
    <div className="shop-container">
      <div className="shop-content">
        <img
          src={shop.imageURL}
          // alt="Emergency Contact"
          className="shop-image"
        />
        <div className="shop-info">
          <p className="shop-name"> Name: {shop.shopName}</p>
          <p className="shop-type">Distance: {shop.distance}</p>
          <p className="shop-area">Area: {shop.Address}</p>
          <p className="shop-contact">Contact No.: {shop.shopNumber}</p>
        </div>

        <div className="shop-buttons">
          <button className="delete-button" onClick={() => setShowModal(true)}>
            <FaDeleteLeft />
          </button>
          <button className="edit-button" onClick={() => handleEditClick(shop)}>
            <FaEdit />
          </button>
        </div>
      </div>
      <ConfirmModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        handleConfirm={handleConfirmDelete}
        text={"Shop"}
      />
    </div>
  );
};

export default ShopCard;
