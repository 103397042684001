import React, { useEffect, useState } from "react";
import "./LosServices.css";
import { useLocation, useParams } from "react-router-dom";
import LosServiceCard from "../../components/LOS/LosService/LosServiceCard";
import LosServicesTopbar from "../../components/LOS/LosService/LosServicesTopbar";
import { GetServiceByType } from "../../api/api";
import image from "../../assets/images/Frame 282.svg";
import TopVendorBlackLists from "../../components/TopSectionVendorBlacklist/TopVendorBlacklist";
function LosServices() {
  const { servicetype } = useParams();
  console.log(servicetype);
  const [services, setServices] = useState([]);
  const [fiteredServices, setFilteredServices] = useState();
  useEffect(() => {
    const fetchData = async () => {
      const data = await GetServiceByType(servicetype);

      setServices(data[0].services);
      setFilteredServices(data[0].services);
    };
    fetchData();
  }, []);

  const filterServices = (value) => {
    if (value === "") {
      setFilteredServices(services);
      return;
    }
    const filterBySearch = services?.filter((item) => {
      if (item.serviceName.toLowerCase().includes(value.toLowerCase())) {
        return item;
      }
    });
    setFilteredServices(filterBySearch);
  };
  return (
    <div className="wrapper">
      <div className="Los-Services-list">
        <div className="desktop-version">
          <TopVendorBlackLists image={image} />
        </div>

        <LosServicesTopbar
          title={servicetype}
          filterServices={filterServices}
        ></LosServicesTopbar>
        {fiteredServices?.map((service) => {
          return (
            <LosServiceCard
              service={service}
              servicetype={servicetype}
            ></LosServiceCard>
          );
        })}
      </div>
    </div>
  );
}

export default LosServices;
