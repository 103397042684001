import React from "react";
import "./TopVendorBlacklist.css";

const TopVendorBlackLists = ({ image }) => {
  return (
    <section id="TopVendorBlacklist">
      <img src={image} alt="Illustration" />
    </section>
  );
};

export default TopVendorBlackLists;
