import React, { useEffect, useState } from "react";
import { GetRedemptionRequests } from "../../api/api";
import "./ReferralEmailDashboard.css";
import { useNavigate } from "react-router-dom";
import { BsSearch } from "react-icons/bs";
import VoucherModal from "./VoucherModal";

function ReferralEmailDashboard() {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedVoucher, setSelectedVoucher] = useState(null);
  const navigate = useNavigate();

  const getData = async () => {
    setLoading(true);
    const results = await GetRedemptionRequests();
    setLoading(false);
    setData(results);
    setFilteredData(results);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleSearchClick = () => {
    if (input === "") {
      setFilteredData(data);
      return;
    }
    const filterBySearch = data.filter((item) => {
      return (
        item.userMobile?.toLowerCase().includes(input.toLowerCase()) ||
        item.status.toLowerCase().includes(input.toLowerCase())
      );
    });
    setFilteredData(filterBySearch);
  };

  useEffect(() => {
    handleSearchClick();
  }, [input]);

  const handleViewVoucher = (voucher) => {
    console.log(voucher);
    setSelectedVoucher(voucher); // Set the voucher to show in modal
    setShowModal(true); // Open the modal
  };

  const handleCloseModal = () => {
    setShowModal(false); // Close the modal
    setSelectedVoucher(null); // Reset the selected voucher
  };

  if (loading) {
    return (
      <div style={{ textAlign: "center", fontWeight: "900" }}>Loading...</div>
    );
  }

  return (
    <div className="redemption-requests-container">
      <div className="search-container-admin" style={{ margin: "20px" }}>
        <input
          className="search-input-admin"
          style={{ border: "0" }}
          placeholder="Search by mobile number, status"
          onChange={(e) => setInput(e.target.value)}
        />
        <BsSearch />
      </div>

      {filteredData?.map((item) => (
        <div key={item._id} className="redemption-requests-card">
          <h2 className="redemption-requests-title">
            {item.userName || "Name not available"}
          </h2>
          <p>
            <strong>Mobile Number:</strong> {item.userMobile || "N/A"}
          </p>
          <p>
            <strong>Amount:</strong> {Math.round(item.amount)} Rs/-
          </p>
          <p>
            <strong>Status:</strong>
            <span
              style={{
                backgroundColor: item.status === "Completed" ? "green" : "red",
                borderRadius: "20px",
                padding: "4px",
                color: "white",
              }}
            >
              {item.status}
            </span>
          </p>
          <p>
            <strong>Preferred Email:</strong> {item.preferredEmail}
          </p>

          {item.status === "Pending" && (
            <button
              className="redemption-requests-email-button"
              onClick={() =>
                navigate("/dashboard/referral/voucherdetails", {
                  state: item._id,
                })
              }
            >
              Enter Email Details
            </button>
          )}

          {/* Button to view voucher details for completed requests */}
          {item.status === "Completed" && (
            <button
              style={{ borderRadius: "40px" }}
              className="redemption-requests-view-voucher-button"
              onClick={() => {
                setSelectedVoucher(item.voucherDetails);
                setShowModal(true);
              }} // Assuming `item.voucherDetails` contains the necessary info
            >
              View Voucher
            </button>
          )}
        </div>
      ))}

      {/* Modal to display voucher details */}
      <VoucherModal
        show={showModal}
        onClose={handleCloseModal}
        voucher={selectedVoucher} // Pass the selected voucher details
      />
    </div>
  );
}

export default ReferralEmailDashboard;
