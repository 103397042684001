import React from "react";
import HeroServiceSlider from "../../components/HeroService/HeroServiceSliderScreen/HeroServiceSlider";
import HeroServiceCardSection from "../../components/HeroService/HeroServiceCardSection/HeroServiceCardSection";
import "./HeroService.css";
function HeroService() {
  return (
    <div className="wrapper">
      <div className="hero-service-page">
        <HeroServiceSlider />
        <HeroServiceCardSection />
      </div>
    </div>
  );
}
export default HeroService;
