import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";

import HeaderAdmin from "../admincomponents/Header/Headeradmin";
import AdminDashboard from "../adminpages/AdminDashBoard/AdminDashBoard";
import ShopHome from "../adminpages/ShopHome/ShopHome";
import CreateShop from "../adminpages/CreateShops/CreateShop";
import EmergencyHome from "../adminpages/EmergencyHome/EmergencyHome";
import EmergencyContactForm from "../adminpages/AddEmergency/EmergencyAdd";
import HomeAdmin from "../adminpages/HomeAdmin/HomeAdmin";
import EditCluster from "../adminpages/EditCluster/EditCluster";
import CreateCluster from "../adminpages/CreateCluster/CreateCluster";
import VendorBlacklistadmin from "../adminpages/VendorBlacklist/VendorBlacklistadmin";
import AddVendor from "../admincomponents/VendorBlacklist/AddVendor";
import AdminLOS from "../adminpages/LOS/AdminLOS";
import AddLosAdmin from "../adminpages/LOS/AddLosAdmin";
import EditLosAdmin from "../adminpages/LOS/EditLosAdmin";

import ReferralDashboard from "../adminpages/Referral/ReferralDashboard";
import ReferralFormAdmin from "../adminpages/Referral/ReferralFormAdmin";
import VoucherDetailsForm from "../adminpages/Referral/VoucherDetailsForm";
import ReferralEmailDashboard from "../adminpages/Referral/ReferralEmailDashboard";
import DriveAdmin from "../adminpages/Drives/DriveAdmin";

function AuthRoutes() {
  const [clusterdata, setClusterData] = useState([]);
  const handleFormSubmit = (shopData) => {
    console.log("Shop Data:", shopData);
  };
  const handleEmergencySubmit = (emergency) => {
    console.log("Emergency Data:", emergency);
  };

  return (
    <div>
      <HeaderAdmin></HeaderAdmin>
      <Routes>
        <Route path="/" element={<AdminDashboard />}></Route>

        <Route path="/shops" element={<ShopHome />}></Route>
        <Route path="/drives" element={<DriveAdmin />}></Route>
        <Route
          path="/shops/shopAdd"
          element={<CreateShop onSubmit={handleFormSubmit} />}
        ></Route>
        <Route path="/emergency" element={<EmergencyHome />}></Route>
        <Route
          path="/emergency/emergencyAdd"
          element={<EmergencyContactForm onSubmit={handleEmergencySubmit} />}
        ></Route>
        <Route
          path="/cluster-manage"
          element={
            <HomeAdmin data={clusterdata} setData={setClusterData}></HomeAdmin>
          }
        ></Route>
        <Route
          path="/homeAdmin/edit-cluster"
          element={<EditCluster setData={setClusterData} />}
        ></Route>
        <Route
          path="/homeAdmin/create-cluster"
          element={<CreateCluster setData={setClusterData} />}
        ></Route>
        <Route
          path="/homeAdmin/vendor-blacklist-admin"
          element={<VendorBlacklistadmin></VendorBlacklistadmin>}
        ></Route>
        <Route
          path="/homeAdmin/vendor-blacklist-admin/add"
          element={<AddVendor></AddVendor>}
        ></Route>

        <Route path="/los" element={<AdminLOS></AdminLOS>}></Route>
        <Route path="/los/add" element={<AddLosAdmin></AddLosAdmin>}></Route>
        <Route path="/los/edit" element={<EditLosAdmin></EditLosAdmin>}></Route>
        <Route path="/referral">
          <Route
            index
            element={<ReferralDashboard></ReferralDashboard>}
          ></Route>
          <Route
            path="referralForm"
            element={<ReferralFormAdmin></ReferralFormAdmin>}
          ></Route>
          <Route
            path="voucherdetails"
            element={<VoucherDetailsForm></VoucherDetailsForm>}
          ></Route>
          <Route
            path="redemptionrequests"
            element={<ReferralEmailDashboard />}
          ></Route>
        </Route>
      </Routes>
    </div>
  );
}

export default AuthRoutes;
