import React, { useEffect, useState } from "react";
import SearchIcon from "../../../assets/images/Search icon.png";
import "./LosServicesTopbar.css";
import arrow from "../../../assets/images/ArrowBAck.svg";
import { useNavigate } from "react-router-dom";

function LosServicesTopbar({ title, filterServices }) {
  const [input, setInput] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    filterServices(input);
  }, [input]);
  return (
    <div className="Los-Services-Topbar">
      <div className="Los-Services-Topbar-left">
        <button
          className="servicelist-backbtn"
          onClick={() => {
            navigate(-1);
          }}
        >
          <img src={arrow}></img>
        </button>
        <h1 className="servicelist-title">{title}</h1>
      </div>
      <div className="los-search-container">
        <input
          style={{ margin: 0 }}
          type="text"
          placeholder="Search"
          className="los-search-input"
          onChange={(e) => {
            setInput(e.target.value);
          }}
        />
        <button className="los-search-button">
          <img src={SearchIcon}></img>
        </button>
      </div>
    </div>
  );
}

export default LosServicesTopbar;
