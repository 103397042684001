import React from "react";
import "./ShopCard.css";
import Call from "../../assets/images/Call.png";
import openIcon from "../../assets/images/TimeOpen.svg";
import closeIcon from "../../assets/images/TimeClose.svg";
import { getShopStatus } from "./GetShopStatus";

const ShopCard = ({ shops, onShopClick }) => {
  return (
    <div className="shop-cards-container" style={{ position: "relative" }}>
      {shops?.map((shop, index) => (
        <div
          className={`cards delay-${index}`}
          key={shop._id}
          onClick={() => onShopClick(shop)}
        >
          <div className="shop-header">
            <div className="shop-image-container">
              <img src={shop.imageURL} alt="Shop" className="shop-image-ui" />
            </div>
            <div className="shop-status">
              <img
                src={
                  getShopStatus(shop.openTime, shop.closeTime, shop.closeOn) ===
                  "open"
                    ? openIcon
                    : closeIcon
                }
                alt={getShopStatus(shop.openTime, shop.closeTime, shop.closeOn)}
                className="status-icon"
              />
            </div>
          </div>
          <h3 className="shop-name">
            {shop.shopName.length > 20
              ? `${shop.shopName.substring(0, 15)}...`
              : shop.shopName}
          </h3>
          <p className="shop-role">{shop.distance}</p>
          <p className="shop-contact">
            <img src={Call} alt="Call Icon" className="call-icon" />{" "}
            {shop.shopNumber}
          </p>
          <div>
            <div>
              <div className="not-for-desktop">
                <p className="shop-reason">
                  <strong>HyperMarket:</strong>
                  {shop.shopDescription.length > 15
                    ? `${shop.shopDescription.substring(0, 11)}... `
                    : shop.shopDescription}

                  {shop.shopDescription.length > 20 && (
                    <a href="#">Read Full</a>
                  )}
                </p>
              </div>

              <div className="desktop-version">
                <p className="shop-reason">
                  <strong>HyperMarket:</strong>
                  {shop.shopDescription}
                </p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ShopCard;
