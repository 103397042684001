import React, { useEffect } from "react";
import "./CongratsModal.css"; 
import Claimsmile from "../../../assets/images/ProfileScreen/Claim smile 5.svg";

const CongratsModal = ({ isOpen, onClose }) => {
  
  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        onClose(); // Close the modal after 4.5 seconds
      }, 2000);

      
      return () => clearTimeout(timer);
    }
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  
  const handleOverlayClick = (e) => {
    if (e.target.className === "Congrats-modal-overlay") {
      onClose();
    }
  };

  return (
    <div className="Congrats-modal-overlay" onClick={handleOverlayClick}>
      <div className="Congrats-modal-content">
        <p>🎉 Congratulations! You've reached ₹500, which you can redeem now in your Email ID.</p>
        <img src={Claimsmile} alt="emoji" className="Congrats-emoji" />
      </div>
    </div>
  );
};

export default CongratsModal;
