import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { URL } from "../../env";
import "./ProfileScreen.css";
import Rupee from "../../assets/images/ProfileScreen/Rupee.svg";
import EditIcon from "../../assets/images/ProfileScreen/EditIcon.svg";
import iIcon from "../../assets/images/ProfileScreen/iIcon.svg";
import ReferralModal from "./ReferralModal/ReferralModal";
import { AuthContext } from "../../context/AuthContext";
import RedeemModal from "./RedeemModal/RedeemModal";
import InsufficientBalanceModal from "./InsufficientBalanceModal/InsufficientBalanceModal";
import EmailModal from "./AddEmailModal/EmailModal";
import CongratsModal from "./CongratulationModal/CongratsModal";
import SparkleImage from "../../assets/images/ProfileScreen/Sparkle Animation.gif";
import CelebrationAnimation from "../../assets/images/ProfileScreen/Celebration Animation.svg";

import Vouchericon from "../../assets/images/ProfileScreen/Voucher icon.svg";
import Rupeecolour from "../../assets/images/ProfileScreen/Rupee colour.svg";
import InprogressVoucherModal from "./Inprogressvoucher/InprogressVoucherModal";
import EmptyVoucherModal from "./ZeroVoucher/EmptyVoucher";
import defaultAvatar from "../../assets/images/defaultIocn.svg";
import {
  GetLatestRedemptionRequests,
  GetUserRedemptionRequests,
} from "../../api/api";
import { MdDashboard } from "react-icons/md";
import Spinner from "../Spinner/Spinner";

const ProfileScreen = () => {
  const [userData, setUserData] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentValue, setCurrentValue] = useState(0); // Initial referral bonus is 0
  const maxValue = 2000;
  const { user, setUser, isLoading } = useContext(AuthContext);
  const [isRedeemModalVisible, setIsRedeemModalVisible] = useState(false);
  const [isCongratsModalVisible, setIsCongratsModalVisible] = useState(false);
  const [
    isInsufficientBalanceModalVisible,
    setIsInsufficientBalanceModalVisible,
  ] = useState(false);
  const [isEmailModalVisible, setIsEmailModalVisible] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState("");

  const [isInprogressVoucherModalVisible, setIsInprogressVoucherModalVisible] =
    useState(false);
  const [isEmptyVouchervisible, setEmptyVoucherModalVisible] = useState(false);
  const [selectedImageURL, setSelectedImageURL] = useState(null);

  const [redReq, setRedReq] = useState();
  const navigate = useNavigate();
  const [avatarUrl, setAvatarUrl] = useState("");

  useEffect(() => {
    const fetchUser = async () => {
      if (user) {
        setUserData(user);
      }

      if (user && user.referralBonus !== undefined) {
        const res = await GetLatestRedemptionRequests(user._id, 1);

        if (res) {
          setRedReq(res[0]);
        }

        setCurrentValue(user.referralBonus);
      } else {
        console.warn("Referral Bonus is not defined");
        setCurrentValue(0);
      }
    };
    fetchUser();
    const fetchRedReq = async () => {};
    fetchRedReq();
  }, []);

  useEffect(() => {
    if (userData && userData?.avatar) {
      const constructedUrl = `${URL}/blacklist/image/${userData?.avatar}`;
      setAvatarUrl(constructedUrl);
    }
  }, [userData]);

  const handlebuttonClick = async (val) => {
    if (val === "process") {
      setIsInprogressVoucherModalVisible(true);
    } else if (val === "redeem") {
      if (currentValue < 500 && currentValue !== 0) {
        setIsInsufficientBalanceModalVisible(true);
      } else if (currentValue === 0) {
        setIsRedeemModalVisible(true);
      } else if (currentValue === 0) {
        setIsRedeemModalVisible(true);
      } else {
        setIsEmailModalVisible(true);
      }
    } else if (val === "claim") {
      // setRedReq(res);
      navigate("/voucher");
    }
  };

  const handleVoucherClick = async () => {
    if (userData?._id) {
      const res = await GetUserRedemptionRequests(userData?._id);
      if (res?.length === 0) {
        setEmptyVoucherModalVisible(true);
      } else if (res?.length >= 0) {
        navigate("/voucher");
      }
    }

    setEmptyVoucherModalVisible(true);
  };

  const closeEmptyVoucherModal = () => {
    setEmptyVoucherModalVisible(false);
  };

  const closeInprogressVoucherModal = () => {
    setIsInprogressVoucherModalVisible(false);
  };

  const closeRedeemModal = () => {
    setIsRedeemModalVisible(false);
  };

  const closeInsufficientBalanceModal = () => {
    setIsInsufficientBalanceModalVisible(false);
  };

  const handleLogout = () => {
    localStorage.removeItem("ilaqaAuthDetails");
    setUser(null);
    navigate("/signin");
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleEditClick = () => {
    navigate("/register");
  };

  const handleImageClick = () => {
    if (avatarUrl) {
      setSelectedImageURL(avatarUrl);
      setIsModalOpen(true);
    }
  };

  if (isLoading || !userData) return <Spinner />;

  return (
    <div className="wrapper">
      <div className="profile-card">
        <div className="profile-header">
          <div className="avatar-profile" onClick={handleImageClick}>
            <img
              src={avatarUrl || defaultAvatar}
              alt="Profile Avatar"
              style={{
                width: "80px",
                height: "80px",
                objectFit: "cover",
                border: "1px solid #0c60ca",
              }}
            />
          </div>
          <div className="greeting">
            <span>Hello</span>
            <h2>{userData?.fullName}</h2>
          </div>

          <div
            className="parentdivcolumn"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "2rem",
            }}
          >
            <div className="voucherDiv">
              <img
                src={Vouchericon}
                alt="Voucherdiv"
                className="Vouchersection"
                style={{ width: "22px", marginTop: "0rem", cursor: "pointer" }}
                onClick={handleVoucherClick}
              />
            </div>
            <div className="edit-icon">
              <img
                src={EditIcon}
                onClick={handleEditClick}
                alt="EditIcon"
                className="EditIcon"
                style={{ width: "25px" }}
              />
            </div>
            {(userData?.role === "admin" ||
              userData?.role === "superadmin") && (
              <div
                style={{}}
                onClick={() => {
                  navigate("/dashboard");
                }}
              >
                <MdDashboard size={25} color="#0C60CA" />
              </div>
            )}
          </div>
        </div>

        <div className="profile-info">
          <div className="info-item">
            <h4>Address</h4>
            <p>
              {`${userData?.area}, ${
                userData?.society === "other"
                  ? userData?.manualAddress && `${userData?.manualAddress}`
                  : `${userData?.society}, ${userData?.flatNumber}`
              }`}
            </p>

            <hr />
          </div>
          <div className="info-item">
            <h4>Mobile</h4>
            <p>{userData?.mobile}</p>
            <hr />
          </div>
          <div className="info-item">
            <h4>Email</h4>
            <p>{selectedEmail || userData?.email}</p>
            <hr />
          </div>
        </div>

        <div className="logout" onClick={handleLogout}>
          Log Out
        </div>

        <div className="referral-header">
          <span style={{ color: "#2b87d1" }} onClick={handleOpenModal}>
            Know more about referral bonus
            <img
              src={iIcon}
              alt="iIcon"
              className="iIcon"
              style={{ textAlign: "right" }}
            />
          </span>
        </div>

        <div className="referral-section">
          <div className="referral-bonus">
            <div style={{ position: "relative" }}>
              <div className="progress-bar">
                <div
                  className="progress-section green"
                  style={{
                    width:
                      currentValue === 0 && redReq?.claimStatus === "Claimed"
                        ? "5%"
                        : `${
                            ((Number(currentValue) +
                              (redReq?.claimStatus === "unClaimed"
                                ? Number(redReq.amount)
                                : 0)) /
                              Number(maxValue)) *
                            100
                          }%
`,
                  }}
                ></div>

                {currentValue >= 500 &&
                  (redReq?.claimStatus === "Claimed" ||
                    redReq?.claimStatus === undefined) && (
                    <div
                      className="thumb-images"
                      style={{
                        position: "absolute",
                        left: `5%`,
                      }}
                    >
                      <img
                        src={CelebrationAnimation}
                        alt="Celebration"
                        className="celebration-image"
                        style={{
                          width: "258px",

                          marginLeft: "10px",
                          marginRight: "-15rem",
                          marginTop: "3rem",
                        }}
                      />
                      <img
                        src={SparkleImage}
                        alt="Sparkle"
                        className="sparkle-thumb"
                      />
                    </div>
                  )}
              </div>
              <div
                className="progress-labels"
                style={{
                  position: "absolute",
                  top: "10px",
                  right: 0,
                  left: 0,
                }}
              >
                <span style={{ color: "green", marginInline: "10px" }}>0</span>

                <span
                  style={{
                    color: "#f57c00",
                    fontWeight: "bold",
                  }}
                >
                  {maxValue}
                </span>
              </div>
              {Number(currentValue) +
                (redReq?.claimStatus === "unClaimed"
                  ? Number(redReq.amount)
                  : 0) >
                10 && (
                <div
                  className="progress-labels"
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: 0,
                    left: 0,
                    display: "flex",
                    justifyContent: "flex-end",
                    width:
                      currentValue === 0 && redReq?.claimStatus === "Claimed"
                        ? "5%"
                        : `${
                            ((Number(currentValue) +
                              (redReq?.claimStatus === "unClaimed"
                                ? Number(redReq.amount)
                                : 0)) /
                              Number(maxValue)) *
                            100
                          }%
`,
                  }}
                >
                  <span
                    style={{
                      color: "green",
                    }}
                  >
                    {Math.round(
                      Number(currentValue) +
                        (redReq?.claimStatus === "unClaimed"
                          ? Number(redReq.amount)
                          : 0)
                    )}
                  </span>
                </div>
              )}
            </div>
            <div className="bonus-details-wrapper ">
              <div className="bonus-details">
                <div
                  style={{ position: "absolute", top: "-15px", right: "10px" }}
                >
                  {currentValue > 500 &&
                    redReq?.status === "Completed" &&
                    redReq?.claimStatus === "unClaimed" && (
                      <button
                        className="redeem-btn"
                        onClick={() => {
                          handlebuttonClick("claim");
                        }}
                        style={{ backgroundColor: "#0C60CA" }}
                      >
                        Claim
                      </button>
                    )}
                  {currentValue < 500 &&
                    redReq?.status === "Completed" &&
                    redReq?.claimStatus === "unClaimed" && (
                      <button
                        className="redeem-btn"
                        onClick={() => {
                          handlebuttonClick("claim");
                        }}
                        style={{ backgroundColor: "#0C60CA" }}
                      >
                        Claim
                      </button>
                    )}
                  {((redReq?.status === "Completed" &&
                    redReq?.claimStatus === "Claimed") ||
                    !redReq) && (
                    <button
                      className="redeem-btn"
                      onClick={() => {
                        handlebuttonClick("redeem");
                      }}
                      // disabled={currentValue < 500}
                      style={{
                        backgroundColor:
                          currentValue < 500 ? "gray" : "#469B31",
                      }}
                    >
                      Redeem
                    </button>
                  )}
                  {redReq?.status === "Pending" && (
                    <button
                      className="redeem-btn"
                      onClick={() => {
                        handlebuttonClick("process");
                      }}
                      style={{ backgroundColor: "#DF9B28" }}
                    >
                      In Progress
                    </button>
                  )}
                </div>
                <div className="rupeeimg" style={{ flex: 1 }}>
                  <img
                    src={currentValue >= 500 ? Rupeecolour : Rupee}
                    alt="Rupee"
                    className="Rupeeimage"
                    style={{
                      width: "32px",
                      opacity: currentValue >= 500 ? 1 : 0.5,
                    }}
                  />
                </div>
                <div style={{ flex: 8 }}>
                  <h3 style={{ fontWeight: 600, fontSize: "20px" }}>
                    Referral Bonus
                  </h3>
                  <p style={{ fontWeight: 300, fontSize: "12px" }}>
                    Note: You can redeem amounts in increments of ₹500, with a
                    maximum of ₹1000.
                  </p>
                </div>

                <span
                  style={{
                    fontSize: "12px",
                    fontWeight: 800,
                    textAlign: "center",
                    flex: 4,
                    marginTop: "20px",
                    color: "#0c60ca",
                  }}
                >{`${Math.round(
                  currentValue +
                    (redReq?.claimStatus === "unClaimed"
                      ? Number(redReq.amount)
                      : 0)
                )}/500`}</span>
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <div
                  className="add-email"
                  style={{
                    color: "#007bff",
                    cursor: "pointer",
                    textAlign: "right",
                  }}
                >
                  {redReq?.status === "Pending" ? redReq?.preferredEmail : ""}
                </div>
              </div>
            </div>
          </div>
        </div>

        <ReferralModal isVisible={isModalOpen} closeModal={handleCloseModal} />

        <RedeemModal
          isOpen={isRedeemModalVisible}
          onClose={closeRedeemModal}
          userName={userData?.fullName}
        />

        <InsufficientBalanceModal
          isOpen={isInsufficientBalanceModalVisible}
          onClose={closeInsufficientBalanceModal}
        />

        <CongratsModal
          isOpen={isCongratsModalVisible}
          onClose={() => setIsCongratsModalVisible(false)}
        />

        {isEmailModalVisible && (
          <EmailModal
            id={user._id}
            show={isEmailModalVisible}
            onClose={() => setIsEmailModalVisible(false)}
            existingEmail={userData?.email}
          />
        )}

        {isInprogressVoucherModalVisible && (
          <InprogressVoucherModal onClose={closeInprogressVoucherModal} />
        )}

        {isEmptyVouchervisible && (
          <EmptyVoucherModal
            isOpen={isEmptyVouchervisible}
            onClose={closeEmptyVoucherModal}
          />
        )}
      </div>
    </div>
  );
};

export default ProfileScreen;
