import React from "react";
import "./AboutUs.css";

import Anur from "../../../assets/images/Anur.svg";
import logo from "../../../assets/images/logo.png";
import whoWeAreImg from "../../../assets/images/3721429 1.svg";
import linkedin from "../../../assets/images/LinkedIn icon.svg";
import team from "../../../assets/images/k.svg";
import { useNavigate } from "react-router-dom";
import OurJourney from "../ourJourney/OurJourney";
function AboutUs() {
  const navigate = useNavigate();
  return (
    <div className="about-us">
      <div className="who-we-are">
        <div className="who-we-are-text">
          <h1 className="blue-heading">
            Who <span className="orange-heading"> We</span> Are
          </h1>
          <p className="paras">
            At iLaqa, we aim to bring back the close-knit, supportive feel of a
            village to modern life. Instead of searching far for help with
            everyday tasks, we encourage people to connect with their neighbours
            first.
          </p>
          <p className="paras">
            Our vision is to create neighbourhoods where people know, support,
            and grow together. By fostering local connections, we make life
            easier, more affordable, and fulfilling. iLaqa is about
            strengthening bonds, so everyone feels valued, connected, and part
            of something bigger
          </p>
        </div>
        <div className="who-we-are-img">
          <img src={whoWeAreImg} className="who-we-are-page-img"></img>
        </div>
      </div>
      <div className="our-journey who-we-are">
        <h1 className="blue-heading">
          Our <span className="orange-heading"> Journey</span> So Far
        </h1>

        <p className="paras">
          iLaqa started in November 2022 as a passion project, connecting
          neighbours with trusted service providers. It quickly grew, and today,
          we're a registered company with over 3,000 users—our "neighbours." Our
          journey has been fueled by a shared belief in the power of community
          and the dedication of a growing, passionate team. As we continue to
          develop new ways to meet the evolving needs and trust of our
          neighbourhood, we remain committed to our original mission: to create
          a connected, supportive community where everyone can
          <span className="opacity-reduced-text">thrive together.</span>
          <button
            className="read-full"
            style={{ margin: 0, padding: 0 }}
            onClick={() => {
              navigate("ourjourney");
            }}
          >
            Read Full
          </button>
        </p>
      </div>
      <div className="ourjourney-desktop">
        <OurJourney />
      </div>

      <div className="leadership">
        <h1 className="blue-heading">
          The Leadership team in <span className="orange-heading"> iLaqa</span>
        </h1>
        <div className="a-container-wrapper">
          <div
            className="A-container"
            style={{ gap: "10px", paddingInline: "10px" }}
          >
            <div
              className="A-container-right"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={Anur} className="A-image"></img>
              <div className="desktop-version">
                <p className="key-supporters-names">Anur Ram Puniyani</p>
                <p
                  style={{
                    fontWeight: "600",
                    fontSize: "16px",
                    textAlign: "center",
                    color: "#0C356A",
                    marginBottom: 0,
                  }}
                >
                  Founder & CEO , iLaqa
                  <br />
                  Technologies Pvt Ltd.
                </p>
              </div>
              <div className="not-for-desktop">
                <p
                  className="t-container-left-position"
                  style={{
                    fontWeight: "700",
                    fontSize: "12px",
                    marginTop: "20px",
                    color: "#0C356A",
                    marginBottom: 0,
                  }}
                >
                  Connect on{" "}
                </p>
                <a
                  href="https://www.linkedin.com/in/anur-puniyani-32a22a21/"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <img
                    src={linkedin}
                    style={{ width: "20px", height: "20px" }}
                  ></img>
                </a>
              </div>
            </div>{" "}
            <div className="A-container-left">
              <p className="t-container-left-name">Anur Ram Puniyani</p>
              <p className="t-container-left-position">
                Founder & CEO , iLaqa Technologies Pvt Ltd.
              </p>
              <p
                className="t-container-left-description"
                style={{ marginBlock: "20px" }}
              >
                He is the visionary behind iLaqa, starting this journey with a
                passion for bringing people closer together. As our Founder and
                CEO,Anur is the driving force behind our mission, sparking ideas
                and steering the ship with enthusiasm.
              </p>
              <div className="A-read-more" style={{ display: "flex" }}>
                <button
                  onClick={() => {
                    navigate("ourceo");
                  }}
                  className="A-read-more-button"
                >
                  Read More
                </button>
              </div>
            </div>
          </div>
          <div className="desktop-version">
            <h1 className="blue-heading" style={{ fontSize: "40px" }}>
              Meet
              <span className="orange-heading"> our</span>
              Founder & CEO
            </h1>
            <p className="paras">
              Anur Ram Puniyani is the visionary behind iLaqa, starting this
              journey with a passion for bringing people closer together. As our
              Founder and CEO, Anur is the driving force behind our mission,
              sparking ideas and steering the ship with enthusiasm. His
              dedication to building a connected community has turned iLaqa from
              a local initiative into a thriving network of 3,000+ "neighbours."
              With Anur at the helm, iLaqa continues to grow and make a positive
              impact, one neighbourhood at a time.
            </p>
            <div className="desktop-version">
              <p
                className="t-container-left-position"
                style={{
                  fontWeight: "700",
                  fontSize: "20px",
                  marginTop: "20px",
                  color: "#0C356A",
                  marginBottom: 0,
                  textAlign: "left",
                }}
              >
                Connect on{" "}
              </p>
              <a
                href="https://www.linkedin.com/in/anur-puniyani-32a22a21/"
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <img
                  src={linkedin}
                  style={{ width: "40px", height: "40px" }}
                ></img>
              </a>
            </div>
          </div>
        </div>

        <div className="desktop-version">
          <div className="letter-ceo">
            <h1 className="blue-heading" style={{ marginBottom: "20px" }}>
              <span className="orange-heading">Letter from </span>
              our Chief Executive Officer
            </h1>
            <div
              className="journey-meaning-mission-para"
              style={{ paddingInline: "14px" }}
            >
              <p className="paras" style={{ marginBlock: "20px" }}>
                Dear iLaqa Neighbour,
              </p>
              <p className="paras">
                As I reflect on our journey, I feel immense pride and gratitude.
                What started as a simple idea to connect neighbours with trusted
                service providers has grown into a community of over 3,000, all
                working toward a better neighbourhood.
              </p>
              <p className="paras" style={{ marginTop: "20px" }}>
                At iLaqa, our mission is to create meaningful connections that
                strengthen our community. Your trust and support have made iLaqa
                more than just a platform—it’s a way of life
              </p>
              <p className="paras">
                Looking ahead, we’re excited about the future. Our team is
                committed to improving and expanding our services to better meet
                your needs, while staying true to our core values.
              </p>
              <p className="paras">
                Thank you for being an essential part of the iLaqa family. Your
                involvement drives us to continually grow and serve you better.
              </p>
              <p
                className="paras"
                style={{ marginTop: "40px", marginBottom: 0 }}
              >
                With sincere gratitude,
              </p>
              <p className="paras" style={{ marginBlock: 0 }}>
                Anur Ram Puniyani
              </p>
              <p className="paras" style={{ marginBlock: 0 }}>
                Chief Executive Officer
              </p>{" "}
              <p
                className="paras"
                style={{
                  marginBlock: 0,
                  marginBottom: "40px",
                }}
              >
                iLaqa
              </p>
            </div>
          </div>
        </div>

        <div className="t-container">
          <div className="t-container-left">
            <p className="t-container-left-name">Tanmay Puniyani</p>
            <p className="t-container-left-position">
              Co-founder , iLaqa <br></br>Technologies Pvt Ltd.
            </p>
            <p
              className="t-container-left-description"
              style={{ textAlign: "center" }}
            >
              She is the heart and soul of iLaqa, overseeing every neighbour and
              service with her big-hearted, creative touch. As co-founder, she’s
              deeply involved in the daily operations, ensuring everything runs
              smoothly and personally connecting with our 3,000+ users. Tanmay’s
              dedication and warmth make her a cherished figure in our
              community, driving our mission forward with passion and care.
            </p>
          </div>
        </div>
      </div>
      <div className="team">
        <h1 className="team-heading">
          Our Fabulous <span className="orange-heading"> Team </span>Turning
          iLaqa into a Reality
        </h1>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            src={team}
            style={{
              marginBlock: "25px",
              objectFit: "contain",
            }}
            className="team-image"
          ></img>
        </div>
      </div>
      <div className="about-us-contact-us">
        <h1 className="about-us-contact-us-text">Contact Us</h1>
        <div className="about-us-contact-us-section">
          <div className="about-us-contact-us-left">
            <div>
              <span style={{ fontWeight: 700 }}>Official Number</span> :
              8008580248
            </div>
            <div>
              <span style={{ fontWeight: 700 }}>Official Email</span> -
              heyneighbour@iLaqa.com
            </div>
            <div>
              <span style={{ fontWeight: 700 }}>Official Address</span>: 1102
              Building Number 5 , MHADA Deluxe Apartment , Rambaug, Powai ,
              Mumbai, 400076
            </div>
          </div>
          <div className="about-us-contact-us-right">
            <img src={logo}></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
