import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./AddVendor.css";
import { GetVendorBlacklists, PostVendor } from "../../api/api";
import defaultimage from "../../assets/images/charcterblacklist.png";

function AddVendor() {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [formData, setFormData] = useState({
    vendorName: "",
    vendorProfession: "",
    mobileNumber: "+91",
    image: null,
    reason: "",
  });
  const [previewImage, setPreviewImage] = useState("");

  const handleChange = (e) => {
    if (e.target.name === "image") {
      const file = e.target.files[0];
      if (file && file.size > 2 * 1024 * 1024) {
        setErrorMessage("*Image should be less than 2 MB.");
        setFormData({ ...formData, image: null });
        setPreviewImage("");
      } else {
        setFormData({ ...formData, image: file });
        setPreviewImage(URL.createObjectURL(file)); // Show preview of the uploaded image
        setErrorMessage("");
      }
    } else {
      setFormData({ ...formData, [e.target?.name]: e.target.value });
    }
  };

  const handleUseDefaultImage = () => {
    // Create a File object for the default image
    fetch(defaultimage)
      .then((res) => res.blob())
      .then((blob) => {
        const defaultFile = new File([blob], "defaultimage.png", {
          type: "image/png",
        });
        setFormData({ ...formData, image: defaultFile });
        setPreviewImage(URL.createObjectURL(defaultFile));
      })
      .catch((err) => console.error("Error loading default image:", err));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.image === null) {
      setErrorMessage("Please choose default or image file");
    }
    const vendorFormData = new FormData();
    for (const item in formData) {
      vendorFormData.append(item, formData[item]);
    }
    await PostVendor(vendorFormData);

    const fetchVendors = async () => {
      const res = await GetVendorBlacklists();
    };
    fetchVendors();
    setFormData({
      vendorName: "",
      vendorProfession: "",
      mobileNumber: "+91",
      image: null,
      reason: "",
    });
    setPreviewImage(""); // Reset the preview image
    navigate("/dashboard/homeAdmin/vendor-blacklist-admin");
  };

  return (
    <form onSubmit={handleSubmit} className="vendor-add-form-container">
      <span>
        *Image file should be less than 2MB. Preferred image formats are PNG or
        JPEG, or a default image can be used.
      </span>

      <div>
        <label className="vendor-add-label">Vendor Name</label>
        <input
          type="text"
          name="vendorName"
          value={formData.vendorName}
          onChange={handleChange}
          className="vendor-add-input"
        />
      </div>

      <div>
        <label className="vendor-add-label">Vendor Profession</label>
        <input
          type="text"
          name="vendorProfession"
          value={formData.vendorProfession}
          onChange={handleChange}
          className="vendor-add-input"
        />
      </div>

      <div>
        <label className="vendor-add-label">Vendor Ph No.</label>
        <input
          type="text"
          name="mobileNumber"
          placeholder={"+91"}
          value={formData.mobileNumber}
          onChange={handleChange}
          className="vendor-add-input"
        />
      </div>

      <div>
        <label className="vendor-add-label">Vendor Picture</label>
        <input
          type="file"
          name="image"
          onChange={handleChange}
          className="vendor-add-input"
        />
        <p style={{ color: "red", fontSize: "12px" }}>{errorMessage}</p>
        <button
          type="button"
          onClick={handleUseDefaultImage}
          className="use-default-button"
        >
          Use Default Image
        </button>
        <div className="image-preview-container">
          {previewImage && (
            <img
              src={previewImage}
              alt="Preview"
              className="image-preview"
              style={{ width: "100px", height: "100px", borderRadius: "8px" }}
            />
          )}
        </div>
      </div>

      <div>
        <label className="vendor-add-label">Vendor Reason</label>
        <input
          type="text"
          name="reason"
          value={formData.reason}
          onChange={handleChange}
          className="vendor-add-input"
        />
      </div>

      <div className="vendor-add-button-container">
        <button
          type="submit"
          disabled={formData.vendorName === "" || formData.image === null}
          className="submit-button"
        >
          Submit
        </button>
        <button
          className="vendor-add-cancel-button"
          onClick={() => {
            navigate("/dashboard/homeAdmin/vendor-blacklist-admin");
          }}
        >
          Cancel
        </button>
      </div>
    </form>
  );
}

export default AddVendor;
