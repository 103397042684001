import style from "./HeroServiceCardSection.css";
import "./HeroServiceCardSection.css";
import SofaRefunbish from "../../../assets/images/SofaRefunbish.svg";
import Bike from "../../../assets/images/Bike.svg";
import Document from "../../../assets/images/Document.svg";
import Laptop from "../../../assets/images/Laptop.svg";
import SofaClean from "../../../assets/images/SofaClean.svg";
import Grill from "../../../assets/images/Grill.svg";
import BackArrow from "../../../assets/images/BackArrow.svg";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function HeroServiceHeroServicecardSection() {
  const [show, setShow] = useState(false);
  const [selectedServiceId, setSelectedServiceId] = useState(null);

  const services = {
    sofaRefurbishment: {
      title: "Sofa Refurbishment",
      image: SofaRefunbish,
      description: "From Dull to Dazzling - Enchanting Sofa Restoration.",
      newDescription:
        "Let your sofa sparkle like a diva in your living space. Your sofa has seen it all—parties, mishaps, furry friends, and countless cozy moments. It's its moment to shine and reclaim its former glory” Revamp your tired old sofa into a superstar with our Sofa Makeover Maestros.",
      whatsappMessage:
        "I'm interested in Sofa Refurbishment service,Can you please book service for me.",
    },
    sofaCleaning: {
      title: "Sofa Cleaning",
      image: SofaClean,
      description: "Review your sofa: Sparkling clean, Simply fresh.",
      newDescription:
        "Transform your sofa into the centerpiece of comfort and style it was always meant to be—schedule your cleaning today and experience the difference ",
      whatsappMessage:
        "I'm interested in Sofa Cleaning service,Can you please book service for me.",
    },
    invisibleGrills: {
      title: "Invisible Grills",
      image: Grill,
      description: "Invisible Grills - Safety meets Style.",
      newDescription:
        "Enjoy scenic views with our see-through security solution—safety without compromise. Say goodbye to bulky grills and hello to a crystal-clear vista from your windows and balconies with our sleek, barely-there design.",
      whatsappMessage:
        "I'm interested in Invisible Grills service,Can you please book service for me.",
    },
    laptopUpgrade: {
      title: "Laptop Upgrade",
      image: Laptop,
      description: "From Glitches to Greatness - We've Got You Covered.",
      newDescription:
        "Give your laptop a new lease on life! If your trusty device is acting its age, don't toss it aside just yet. With a little repair and upgrade magic, your laptop can be back in the game, running like a dream.",
      whatsappMessage:
        "I'm interested in Laptop Upgrade service,Can you please book service for me.",
    },
    royalEnfieldService: {
      title: "Royal Enfield Service",
      image: Bike,
      description: "Rev up your wheels with top-notch bike repairs.",
      newDescription:
        "Our experts will have you cruising smoothly and safely in a jiffy. Let's get that two-wheeled beauty back on the road where it belongs!.",
      whatsappMessage:
        "I'm interested in Royal Enfield Service,Can you please book service for me.",
    },
    documentation: {
      title: "Documentation",
      image: Document,
      description: "Simplify Your Life with Expert Documentation Service.",
      newDescription:
        "Navigating through paperwork and formalities can be overwhelming. Let us handle all your documentation needs, from PAN and TAN applications to IT returns, GST registrations, and various licenses and certificates. Our expert team ensures that every detail is managed efficiently and accurately, saving you time and reducing stress.",
      whatsappMessage:
        "I'm interested in Documentation service,Can you please book service for me.",
    },
  };

  const handleClose = () => setShow(false);

  const handleShow = (serviceId) => {
    setSelectedServiceId(serviceId);
    setShow(true);
  };

  const handleBookNow = () => {
    const selectedService = services[selectedServiceId];
    const whatsappURL = `https://wa.me/918008580248?text=${encodeURIComponent(
      selectedService.whatsappMessage
    )}`;
    window.open(whatsappURL, "_blank");
  };

  return (
    <div className="hero-card-section-wrapper">
      <div className={style.heroHeroServicecardsection}>
        <h2 className="heroServiceHeader">
          Most sought after services in
          <span className="highlightHeroservice">iLaqa</span>
        </h2>
        <div className="HeroServicecardContainer">
          {Object.keys(services).map((serviceId) => {
            const service = services[serviceId];
            return (
              <div
                key={serviceId}
                className="HeroServicecard"
                onClick={() => handleShow(serviceId)}
              >
                <img
                  src={service.image}
                  alt={service.title}
                  className="HeroServicecardImage"
                />
                <h3 className="title">{service.title}</h3>
                <p className="description">{service.description}</p>
                <div>
                  <h4 className="seemore">See more</h4>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          margin: 0,
          padding: "20px",
          height: "auto",
          transform: show ? "translateY(0)" : "translateY(100%)",
          transition: "transform 0.3s ease-in-out",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
        centered
      >
        <Modal.Header
          className="d-flex justify-content-center"
          style={{
            borderBottom: "none",
            padding: "20px",
            alignItems: "flex-end",
          }}
        >
          <Button
            variant="link"
            style={{
              position: "absolute",
              left: "10px",
            }}
            onClick={handleClose}
          >
            <img src={BackArrow} alt="Back" />
          </Button>
          <Modal.Title className="title" style={{ color: "#0c60ca" }}>
            {services[selectedServiceId]?.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className="text-center"
          style={{
            padding: "20px",
            fontSize: "13px",
          }}
        >
          <img
            src={services[selectedServiceId]?.image}
            alt={services[selectedServiceId]?.title}
            className={style.modalImage}
            style={{
              maxWidth: "50%",
              borderRadius: "10px",
              marginBottom: "15px",
            }}
          />
          <p className="description">
            {services[selectedServiceId]?.newDescription}
          </p>
        </Modal.Body>
        <Modal.Footer
          className="d-flex justify-content-center"
          style={{
            borderTop: "none",
            paddingBottom: "20px",
          }}
        >
          <Button
            variant="primary"
            onClick={handleBookNow}
            style={{
              padding: "8px 20px",
              fontSize: "12px",
              width: "50%",
              borderRadius: "20px",
            }}
          >
            Book Now
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default HeroServiceHeroServicecardSection;
