import React, { useEffect, useState } from "react";
import frame22 from "../../assets/images/Los illustration (1).svg";
import "./LosServiceType.css";
import LosServiceTypeCard from "../../components/LOS/LOSTypes/LosServiceTypeCard";
import LosTypeSearch from "../../components/LOS/LOSTypes/LosTypeSearch";
import { GetServices } from "../../api/api.js";
import LosServiceCard from "../../components/LOS/LosService/LosServiceCard.jsx";
import Loader from "../../components/Loader/GenralLoader.js";
import TopVendorBlackLists from "../../components/TopSectionVendorBlacklist/TopVendorBlacklist.js";
function LosServiceType() {
  const [services, setServices] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredServices, setFilteredServices] = useState([]);

  useEffect(() => {
    const getServiceData = async () => {
      const res = await GetServices();

      setServices(res);
    };
    getServiceData();
  }, []);
  useEffect(() => {
    const filteredServicestemp = services.flatMap((category) =>
      category.services
        .filter((service) => {
          // Normalize and split the search query into words
          const searchWords = searchQuery
            .trim() // Remove leading/trailing spaces
            .split(/\s+/) // Split by one or more spaces
            .map((word) => word.toLowerCase()); // Normalize to lowercase

          // Check if any search word exists in serviceName or description
          return searchWords.some(
            (word) =>
              service.serviceName.toLowerCase().includes(word) ||
              service.description.toLowerCase().includes(word)
          );
        })
        .map((service) => ({
          _id: service._id,
          serviceName: service.serviceName,
          description: service.description,
          subService: service.subService,
          categoryType: category.type, // Include category type if needed
        }))
    );

    console.log(filteredServices);
    setFilteredServices(filteredServicestemp);
  }, [searchQuery]);

  return (
    <div className="wrapper">
      <div className="los-type-page">
        <TopVendorBlackLists image={frame22} />
        <LosTypeSearch
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          filteredServices={filteredServices}
        ></LosTypeSearch>

        {!searchQuery ? (
          <>
            <div className="vendor-header">
              <h2 className="header-title">List of Services</h2>
            </div>
            {services.length ? (
              <LosServiceTypeCard services={services}></LosServiceTypeCard>
            ) : (
              <Loader></Loader>
            )}
          </>
        ) : (
          <div
            style={{
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            {filteredServices.map((service) => {
              return <LosServiceCard service={service}></LosServiceCard>;
            })}
          </div>
        )}
      </div>{" "}
    </div>
  );
}

export default LosServiceType;
