import React, { useContext, useState } from "react";
import "./EmailModal.css";
import crossIcon from "../../../assets/images/Cross Icon.png";
import activeRadioIcon from "../../../assets/images/ProfileScreen/Radio button Active.svg";
import inactiveRadioIcon from "../../../assets/images/ProfileScreen/Radio Button inactive.svg";
import { requestRedemption } from "../../../api/api";
import Spinner from "../../Spinner/Spinner";
import { AuthContext } from "../../../context/AuthContext";

const EmailModal = ({
  id,
  show,
  onClose,

  existingEmail,
}) => {
  const { loadUser } = useContext(AuthContext);
  const [selectedOption, setSelectedOption] = useState("existing");
  const [newEmail, setNewEmail] = useState(""); // Track new email input
  const [loading, setloading] = useState(false);
  if (!show) return null;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setloading(true);
    document.body.style.overflow = "hidden";

    if (selectedOption === "existing") {
      await requestRedemption(id, false, existingEmail);
    } else {
      await requestRedemption(id, true, newEmail);
    }

    setloading(false);
    onClose();
    window.location.reload();
    document.body.style.overflow = "";
  };
  if (loading) {
    return <Spinner />;
  }
  return (
    <div className="emailmodal-backdrop">
      <div className="email-modal">
        <div className="email-modal-header">
          <img
            src={crossIcon}
            alt="Close"
            className="email-close-icon"
            onClick={onClose}
          />
        </div>
        <form onSubmit={handleSubmit} className="email-modal-body">
          <div className="email-option">
            <label
              htmlFor="existingEmail"
              className="email-option-label"
              onClick={() => setSelectedOption("existing")}
            >
              <img
                src={
                  selectedOption === "existing"
                    ? activeRadioIcon
                    : inactiveRadioIcon
                }
                alt="Radio button"
                style={{ marginRight: "15px" }}
                className="radio-icon"
              />
              Use the existing Email to Redeem
            </label>
          </div>
          <div className="email-option">
            <label
              htmlFor="newEmail"
              className="email-option-label"
              onClick={() => setSelectedOption("new")}
            >
              <img
                src={
                  selectedOption === "new" ? activeRadioIcon : inactiveRadioIcon
                }
                alt="Radio button"
                className="radio-icon"
                style={{ marginRight: "15px" }}
              />
              Add new Email to Redeem
            </label>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {selectedOption === "new" && (
              <input
                type="email"
                className="new-email-input"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                required
              />
            )}

            <button type="submit" className="add-button">
              Add
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EmailModal;
