import { Routes, Route } from "react-router-dom";

import Home from "./Pages/Home/Home";
import VendorBlacklist from "./Pages/Vendor-Blacklist/VendorBlacklist";
import "./App.css";

import HeaderLayout from "./route/HeaderLayout";

import Shops from "./Pages/Shops/Shops";
import EmergencyContacts from "./Pages/Emergency/EmergencyContacts";

import LosServiceType from "./Pages/Los/LosServiceType";
import LosServices from "./Pages/Los/LosServices";

import AboutUs from "./Pages/AboutUs/aboutUs/AboutUs";

import OurJourney from "./Pages/AboutUs/ourJourney/OurJourney";
import OurCeo from "./Pages/AboutUs/ourCeo/OurCeo";
import AboutUsHeaderLayout from "./route/AboutUsHeaderLayout";
import LosSubServices from "./Pages/Los/LosSubServices";
import HeroService from "./Pages/HeroService/HeroService";

import ScrollToTop from "./components/Reusable/ScrollToTop";

import ProfileForm from "./components/ProfileForm/ProfileForm";
import ProtectedRoute from "./route/ProtectedRoute";
import AuthRoutes from "./route/AuthRoutes";

import ProfileScreen from "./components/ProfileScreen/ProfileScreen";

import VoucherScreen from "./components/ProfileScreen/VoucherScreen/VoucherScreen";

import SignIn from "./Pages/Authentication/SignIn";
import AuthHeaderLayout from "./route/AuthHeaderLayout";
import NotFound from "./Pages/NotFound/NotFound";
import Tandc from "./Pages/Terms&conditions/Tandc";

function App() {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />}></Route>

        <Route element={<HeaderLayout />}>
          <Route path="/hero-service" element={<HeroService />}></Route>
          <Route path="/vendor-blacklist" element={<VendorBlacklist />}></Route>
          <Route path="/shops" element={<Shops />}></Route>
          <Route path="/emergency" element={<EmergencyContacts />}></Route>

          {/* <Route path="/diwali2024" element={<Diwali2024 />}></Route>
          <Route path="/diwaliA" element={<DiwaliA />}></Route> */}

          <Route path="/services">
            <Route index element={<LosServiceType></LosServiceType>}></Route>
            <Route path=":servicetype">
              <Route index element={<LosServices></LosServices>}></Route>{" "}
              <Route
                path=":service"
                element={<LosSubServices></LosSubServices>}
              ></Route>
            </Route>
          </Route>
        </Route>
        <Route element={<AuthHeaderLayout />}>
          <Route path="/terms&conditions" element={<Tandc />}></Route>
          <Route path="/signin" element={<SignIn />}></Route>

          <Route path="/register" element={<ProfileForm />}></Route>

          <Route path="/voucher" element={<VoucherScreen />}></Route>
          <Route
            path="/profile"
            element=<ProtectedRoute>
              <ProfileScreen></ProfileScreen>
            </ProtectedRoute>
          ></Route>
        </Route>

        <Route path="/aboutus" element={<AboutUsHeaderLayout />}>
          <Route index element={<AboutUs></AboutUs>}></Route>
          <Route path="ourjourney" element={<OurJourney />}></Route>
          <Route path="ourceo" element={<OurCeo />}></Route>
        </Route>

        <Route
          path="/dashboard/*"
          element=<ProtectedRoute>
            <AuthRoutes></AuthRoutes>
          </ProtectedRoute>
        ></Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
