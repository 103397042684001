import React from "react";
import "./VoucherModal.css";
import { URL } from "../../env";

const VoucherModal = ({ show, onClose, voucher }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="voucher-modal-overlay">
      <div
        className="voucher-modal-content"
        style={{ maxHeight: "90vh", overflowY: "scroll" }}
      >
        <h2>Voucher Details</h2>
        {voucher ? (
          <>
            <img
              src={`${URL}/blacklist/image/${voucher.screenshot}`}
              alt="Voucher Screenshot"
              //style={{ maxHeight: "60vh",over }}
              className="voucher-screenshot"
            />
            <p>
              <strong>Expiry Date:</strong> {voucher.expiryDate || "N/A"}
            </p>
            <p>
              <strong>Last 4 Digits of Card:</strong>
              {voucher.lastFourDigits}
            </p>
            <button className="voucher-modal-close-button" onClick={onClose}>
              Close
            </button>
          </>
        ) : (
          <>
            <p>No voucher details available.</p>{" "}
            <button className="voucher-modal-close-button" onClick={onClose}>
              Close
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default VoucherModal;
