import React, { createContext, useEffect, useState } from "react";
import { fetchUserData } from "../api/Auth";
import { useLocation, useNavigate } from "react-router-dom";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Handle loading state
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();
  useEffect(() => {
    const storedAuthDetails =
      JSON.parse(localStorage.getItem("ilaqaAuthDetails")) || {};
    const { ilaqaAccessToken: accessToken, ilaqaPhNumber: number } =
      storedAuthDetails;

    const loadUser = async () => {
      setIsLoading(true);
      try {
        const userData = await fetchUserData(number, accessToken);
        if (userData) {
          setUser(userData);
          setIsAuthenticated(true);
          if (
            location.pathname === "/profile" ||
            location.pathname === "/voucher"
          ) {
            if (!userData.fullName) {
              navigate("/register");
            }
          }
        } else {
          handleUnauthenticated();
        }
      } catch (error) {
        console.error("Error loading user:", error.message);
        handleUnauthenticated();
      } finally {
        setIsLoading(false);
      }
    };

    const handleUnauthenticated = () => {
      setUser(null);
      setIsAuthenticated(false);
      setIsLoading(false);
    };

    if (accessToken && number) {
      loadUser();
    } else {
      handleUnauthenticated();
    }
  }, [navigate]);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        isAuthenticated,
        setIsAuthenticated,
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
