import React, { useState } from "react";
import crossIcon from "../../assets/images/Cross Icon.png";
import Select from "react-select";
import { PostNewDrive } from "../../api/api";
import moment from "moment";

function AddDriveModal({ setIsAddModal, fetchAllDrives, buildings }) {
  const options = buildings?.map((b) => ({
    value: b.name,
    label: b.name,
  }));

  // State to store form data
  const [formData, setFormData] = useState({
    driveName: "",
    societies: [],
    driveDescription: "",
    startsOn: "",
    endsOn: "",
    locationLink: "",
  });

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle multi-select change
  const handleSelectChange = (selectedOptions) => {
    setFormData((prevData) => ({
      ...prevData,
      societies: selectedOptions,
    }));
  };

  // Handle form submit
  const handleSubmit = async (e) => {
    const formattedData = {
      ...formData,
      startsOn: moment(formData.startsOn).format("DD-MM-YYYY"),
      endsOn: moment(formData.endsOn).format("DD-MM-YYYY"),
    };
    e.preventDefault();

    await PostNewDrive(formattedData);
    setIsAddModal(false);
    fetchAllDrives();
  };

  return (
    <div
      className="modal-overlay-admin-drive-modal"
      style={{ padding: "10px" }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div
        className="modal-ontent-admin-drive-modal"
        style={{ minHeight: "80vh", width: "95vw", overflow: "scroll" }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div
          className="close-btn-whatsapp-hero-whatsapp"
          onClick={() => {
            setIsAddModal(false);
          }}
        >
          <img src={crossIcon} alt="Close" />
        </div>
        <h2 className="header-title">Enter Drive Details</h2>
        <form
          onSubmit={handleSubmit}
          style={{
            padding: 0,
            paddingBlock: "20px",
            display: "flex",
            flexDirection: "column",
            gap: "5px",
          }}
        >
          <label className="label">
            Drive Name
            <input
              type="text"
              name="driveName"
              value={formData.driveName}
              onChange={handleChange}
              className="input"
            />
          </label>

          <label>
            Societies
            <Select
              isMulti
              name="societies"
              options={options}
              value={formData.societies}
              onChange={handleSelectChange}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </label>
          <label className="label">
            Drive Description
            <textarea
              name="driveDescription"
              value={formData.driveDescription}
              onChange={handleChange}
              className="input"
            />
          </label>
          <label className="label">
            Starts on
            <input
              type="date"
              name="startsOn"
              value={formData.startsOn}
              onChange={handleChange}
              className="input"
            />
          </label>
          <label className="label">
            Ends On
            <input
              type="date"
              name="endsOn"
              value={formData.endsOn}
              onChange={handleChange}
              className="input"
            />
          </label>
          <label className="label">
            Location Google link
            <input
              type="text"
              name="locationLink"
              value={formData.locationLink}
              onChange={handleChange}
              className="input"
            />
          </label>
          <div className="whtsapp-btn-wrapper-hero-whatsapp">
            <button
              type="submit"
              style={{ marginTop: "10px" }}
              className="button"
            >
              Submit
            </button>
            <button
              type="button"
              className="button"
              style={{ backgroundColor: "brown", marginTop: "10px" }}
              onClick={() => setIsAddModal(false)}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddDriveModal;
