import React, { useState, useEffect } from "react";
import "./AdminLOS.css"; // Import the CSS file
import { DeleteService, GetServices } from "../../api/api.js";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
const AdminLOS = () => {
  const [services, setServices] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const getServiceData = async () => {
      const res = await GetServices();

      setServices(res);
    };
    getServiceData();
  }, []);

  return (
    <div className="admin-los-card-container">
      <button
        className="los-admin-add-service"
        onClick={() => {
          navigate("/dashboard/los/add");
        }}
      >
        Add New Service
      </button>
      {services?.map((service) => (
        <div key={service.type}>
          <h2>{service.type}</h2>
          <div className="admin-los-cards">
            {service?.services?.map((s) => (
              <div key={s?.id} className="admin-los-card">
                <div>
                  <h3>{s?.serviceName}</h3>
                  <p>{s?.descri}</p>

                  <ul>
                    {s?.subService?.map((sub) => {
                      return <li>{sub.name}</li>;
                    })}
                  </ul>
                </div>
                <div className="admin-los-card-buttons">
                  <button
                    className="admin-los-edit-button"
                    onClick={() => {
                      navigate("/dashboard/los/edit", {
                        state: { s: s, typeId: service._id },
                      });
                    }}
                  >
                    <FaRegEdit />
                  </button>
                  <button
                    className="admin-los-delete-button"
                    onClick={() => {
                      const handleDelete = async () => {
                        await DeleteService(service._id, s._id);
                        const res = await GetServices();
                        setServices(res);
                      };
                      let res = window.confirm(
                        `Do you want to delete ${s.serviceName} ?`
                      );
                      if (res) {
                        handleDelete();
                      }
                    }}
                  >
                    <MdDelete />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default AdminLOS;
