import React, { useRef, useEffect } from "react";
import "./EmptyVoucher.css";
import crossIcon from "../../../assets/images/Cross Icon.png";

const EmptyVoucherModal = ({ isOpen, onClose }) => {
  const modalRef = useRef(null);

  // Close modal on clicking outside the modal content
  const handleEmptyOverlayClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose(); // Close modal when clicking outside the modal content
    }
  };

  // Automatically close the modal after 3 seconds

  return (
    <div
      className="emptyvouchermodal-overlay"
      onClick={handleEmptyOverlayClick}
    >
      <div className="emptyvouchermodal-content">
        <img
          src={crossIcon}
          className="modal-close-icon"
          onClick={onClose}
        ></img>

        <div className="emptyvouchermodal-body">
          <p>
            You don't have any vouchers or rewards yet. Start referring friends
            or booking services to earn exciting rewards!
          </p>
        </div>
      </div>
    </div>
  );
};

export default EmptyVoucherModal;
