import React, { useState, useEffect, useContext } from "react";
import "./VoucherScreen.css";
import VoucherScreenIllustration from "../../../assets/images/ProfileScreen/Voucher screen illustration.svg";
import SearchIcon from "../../../assets/images/Search.svg";
import VoucherModal from "../VoucherModal/VoucherModal";
import "./VoucherScreen.css";

import { GetUserRedemptionRequests, updateClaimStatus } from "../../../api/api";
import { AuthContext } from "../../../context/AuthContext";

const VoucherScreen = () => {
  const [activeTab, setActiveTab] = useState("UnClaimed");

  const [redemptionReqs, setRedemptionReqs] = useState([]);
  const [activeRedemptionReqs, setActiveRedemptionReqs] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedVoucher, setSelectedVoucher] = useState(null);

  const [searchQuery, setSearchQuery] = useState("");
  const [noCards, setNoCards] = useState(false);
  const { user } = useContext(AuthContext);

  const handleVoucherClick = async (voucherCode, email, status, id) => {
    setSelectedVoucher({ voucherCode, email });
    setShowModal(true);
    if (status === "unClaimed") {
      const res = await updateClaimStatus(id, "Claimed");
    }
  };

  const handleSearchChange = (e) => {
    if (e.target.value !== " ") {
      setSearchQuery(e.target.value.toLowerCase());
    }
  };
  useEffect(() => {
    const filtereVouchers = redemptionReqs?.filter((item) =>
      item.voucherDetails?.lastFourDigits?.includes(searchQuery)
    );
    setActiveRedemptionReqs(filtereVouchers);
    setRedReqCandUnc(filtereVouchers);
  }, [searchQuery]);
  const fetchData = async () => {
    const id = user?._id;
    if (id) {
      const res = await GetUserRedemptionRequests(id);

      setRedemptionReqs(res);
    }
  };
  useEffect(() => {
    fetchData();
  }, [user]);
  const setRedReqCandUnc = (redemptionReqs) => {
    setNoCards(false);
    if (activeTab === "UnClaimed") {
      const unClaimRedReq = redemptionReqs.filter((item) => {
        if (item.voucherDetails && item.claimStatus === "unClaimed") {
          return item;
        }
      });
      if (unClaimRedReq.length === 0) {
        setNoCards(true);
      }

      setActiveRedemptionReqs(unClaimRedReq);
    } else if (activeTab === "Claimed") {
      const claimRedReq = redemptionReqs.filter((item) => {
        if (item.claimStatus === "Claimed" && item.voucherDetails) {
          return item;
        }
      });
      if (claimRedReq.length === 0) {
        setNoCards(true);
      }

      setActiveRedemptionReqs(claimRedReq);
    }
  };
  useEffect(() => {
    setRedReqCandUnc(redemptionReqs);
  }, [activeTab, redemptionReqs]);

  return (
    <>
      <div className="top-section" style={{ paddingInline: "20px" }}>
        <img
          src={VoucherScreenIllustration}
          alt="Profile Avatar"
          style={{ width: "100%" }}
        />
      </div>
      <div
        style={{
          width: "100vw",
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "10px",
        }}
      >
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <div className="los-search-container">
            <input
              type="number"
              placeholder="Search voucher"
              value={searchQuery}
              onChange={handleSearchChange}
              className="los-search-input"
              style={{ margin: 0 }}
            />
            <button className="search-button">
              <img src={SearchIcon}></img>
            </button>
          </div>
          {!activeRedemptionReqs.length && searchQuery && (
            <div className="no-results-tooltip">No results found</div>
          )}
        </div>
      </div>
      <div
        className="tab-section"
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginTop: "20px",
        }}
      >
        <div
          className={activeTab === "UnClaimed" ? "tab active" : "tab"}
          onClick={() => {
            setActiveTab("UnClaimed");
          }}
          style={{
            padding: "10px 20px",
            cursor: "pointer",
            borderBottom:
              activeTab === "UnClaimed" ? "4px solid #007BFF" : "none",
            color: activeTab === "UnClaimed" ? "#007BFF" : "#000",
            fontWeight: "bold",
          }}
        >
          Unclaimed
        </div>

        <div
          className={activeTab === "Claimed" ? "tab active" : "tab"}
          onClick={() => {
            setActiveTab("Claimed");
          }}
          style={{
            padding: "10px 20px",
            cursor: "pointer",
            borderBottom:
              activeTab === "Claimed" ? "4px solid #007BFF" : "none",
            color: activeTab === "Claimed" ? "#007BFF" : "#000",
            fontWeight: "bold",
          }}
        >
          Claimed
        </div>
      </div>

      <div
        // className="voucher-list"
        style={{ marginTop: "20px", padding: "10px" }}
      >
        {noCards && <p>No cards to claim</p>}
        {activeRedemptionReqs.map((item) => {
          return (
            <div
              className="voucher-list"
              style={{
                color: "black",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                padding: "10px",
                paddingLeft: "30px",
                marginBottom: "10px",
                opacity: item.claimStatus === "unClaimed" ? 1 : 0.6,
              }}
              onClick={() => {
                handleVoucherClick(
                  `xxxxxxxx${item.voucherDetails.lastFourDigits}`,
                  item.preferredEmail,
                  item.claimStatus,
                  item._id
                );
                fetchData();
              }}
            >
              <h3>Amazon Coupon</h3>
              <p>Unlock Your Savings – This is Your Ticket to Great Deals!</p>

              <p
                style={{ fontSize: "18px", fontWeight: "600" }}
              >{`xxxxxxxx${item.voucherDetails.lastFourDigits}`}</p>
            </div>
          );
        })}
      </div>

      {showModal && selectedVoucher && (
        <VoucherModal
          onClose={() => {
            setShowModal(false);
            fetchData();
          }}
          email={selectedVoucher.email}
          voucherCode={selectedVoucher.voucherCode}
        />
      )}
    </>
  );
};

export default VoucherScreen;
