import React from "react";
import "./OtherServicesScreen.css";
import Emergency from "../../assets/images/EmergencyICON.png";
import Shops from "../../assets/images/ShopsICON.png";
import VendorBlacklist from "../../assets/images/VendorBlacklistICON.png";
import { NavLink } from "react-router-dom";
const OtherServicesScreen = ({ openModal }) => {
  return (
    <section className="otherServices-container">
      <div className="line"></div>
      <div className="services">
        <NavLink to={"/emergency"}>
          <div className="serviceItem" onClick={openModal}>
            <img
              className="icon"
              src={Emergency}
              alt="Emergency Contacts Icon"
            />
            <p className="serviceText">Emergency Contacts</p>
          </div>
        </NavLink>
        <NavLink to={"/shops"}>
          <div className="serviceItem" onClick={openModal}>
            <img className="icon" src={Shops} alt="Shops near you Icon" />
            <p className="serviceText">Shops near you</p>
          </div>
        </NavLink>
        <NavLink to={"/vendor-blacklist"}>
          <div className="serviceItem">
            <img
              className="icon"
              src={VendorBlacklist}
              alt="Blacklisted Vendors Icon"
            />
            <p className="serviceText">Blacklisted Vendors</p>
          </div>
        </NavLink>
      </div>
    </section>
  );
};

export default OtherServicesScreen;
