import React, { useEffect, useState } from "react";
import "./Drive.css";
import calender from "../../assets/images/stash_data-date-light.svg";
import { FetchDrivesByArea } from "../../api/api";
import moment from "moment";

function Drive() {
  const [activeTab, setActiveTab] = useState("Live");
  const [drives, setDrives] = useState({
    Live: [],
    Upcoming: [],
    Past: [],
  });
  const [selectedArea, setSelectedArea] = useState("hillpark");

  useEffect(() => {
    const fetchDrives = async () => {
      try {
        const data = await FetchDrivesByArea(selectedArea);

        const currentDate = moment();

        // Categorize drives
        const ongoing = data?.drives.filter((drive) =>
          currentDate.isBetween(
            moment(drive.start_date),
            moment(drive.end_date),
            "day",
            "[]"
          )
        );
        const upcoming = data?.drives.filter((drive) =>
          moment(drive.start_date).isAfter(currentDate)
        );
        const completed = data?.drives.filter((drive) =>
          moment(drive.end_date).isBefore(currentDate)
        );

        setDrives({
          Live: ongoing,
          Upcoming: upcoming,
          Past: completed,
        });
      } catch (error) {
        console.error("Error fetching drives:", error);
      }
    };

    fetchDrives();
  }, [selectedArea]); // Refetch drives when the selected area changes
  const handleBookNow = (name) => {
    window.location.href = `https://wa.me/+917032512444?text=Hello iLaqa, I want to book ${name}`;
  };
  return (
    <div className="drive-container">
      <h2 className="drive-heading">
        <span>iLaqa</span> Drive
      </h2>
      <form className="drive-area-form">
        <label>
          <input
            type="radio"
            name="area"
            value="hillpark"
            checked={selectedArea === "hillpark"}
            onChange={(e) => setSelectedArea(e.target.value)}
          />
          <span>Hill Park</span>
        </label>
        <label>
          <input
            type="radio"
            name="area"
            value="nallagandla"
            checked={selectedArea === "nallagandla"}
            onChange={(e) => setSelectedArea(e.target.value)}
          />
          <span>Nallagandla</span>
        </label>
      </form>
      <div
        className="tab-section"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        {["Live", "Upcoming", "Past"].map((tab) => (
          <div
            key={tab}
            className="tab"
            onClick={() => setActiveTab(tab)}
            style={{
              padding: "10px 20px",
              cursor: "pointer",
              borderBottom: activeTab === tab ? "2px solid #0C60CA" : "none",
              color:
                tab === "Live"
                  ? "#FF0000"
                  : tab === "Upcoming"
                  ? "#1C7A04"
                  : "#00215E",
            }}
          >
            {tab.charAt(0).toUpperCase() + tab.slice(1)}
          </div>
        ))}
      </div>
      <div className="drives-wrapper">
        {drives[activeTab].length === 0 ? ( // Check if there are no events
          <div className="no-events-message">
            No {activeTab.toLowerCase()} events are currently scheduled. We will
            notify you soon with updates.
          </div>
        ) : (
          drives[activeTab].map((drive) => (
            <div className="drive-card" key={drive._id}>
              <div className="drive-card-date">
                <img src={calender} alt="Calendar" />
                <span className="date-month">
                  {moment(drive.start_date).format("Do MMM")}
                </span>
                <span className="week">
                  {moment(drive.start_date).format("dddd")}
                </span>
              </div>

              <div className="drive-card-text">
                <h4 className="drive-card-heading">{drive.name}</h4>
                <p className="drive-card-desci">
                  <span>Description:</span> {drive.description}
                </p>
              </div>
              {activeTab !== "Past" && (
                <div className="drive-card-button">
                  <button
                    className="drive-book-now"
                    onClick={() => handleBookNow(drive.name)}
                  >
                    Book Now
                  </button>
                  {/* <div className="drive-read-more">Read More</div> */}
                </div>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default Drive;
