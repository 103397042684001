import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./AdminDashBoard.css";

const AdminDashboard = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0); // Reset scroll to top
  }, []);
  return (
    <div className="AdminContainer">
      <button className="button" onClick={() => navigate("/profile")}>
        Your Profile
      </button>
      <button
        className="button"
        onClick={() => navigate("/dashboard/cluster-manage")}
      >
        Cluster Management
      </button>
      <button className="button" onClick={() => navigate("/dashboard/shops")}>
        Shop
      </button>
      <button
        className="button"
        onClick={() => navigate("/dashboard/homeAdmin/vendor-blacklist-admin")}
      >
        Vendor
      </button>
      <button
        className="button"
        onClick={() => navigate("/dashboard/emergency")}
      >
        Emergency
      </button>
      <button className="button" onClick={() => navigate("/dashboard/los")}>
        List Of Services
      </button>
      <button
        className="button"
        onClick={() => navigate("/dashboard/referral")}
      >
        Referral
      </button>
      <button className="button" onClick={() => navigate("/dashboard/drives")}>
        Drives
      </button>
    </div>
  );
};

export default AdminDashboard;
