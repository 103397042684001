import Sofamakeoverandclean from "../../../assets/images/Sofamakeoverandclean.png";
import { Carousel } from "react-bootstrap";
import "./HeroservicesSlider.css";
import style from "./HeroservicesSlider.css";
import Documentation from "../../../assets/images/Documentation.png";
import EnfieldService from "../../../assets/images/EnfieldService.png";
import InvisibleGrill from "../../../assets/images/InvisibleGrill.png";
import Laptopupgrade2 from "../../../assets/images/Frame 289.png";
import TopVendorBlackLists from "../../TopSectionVendorBlacklist/TopVendorBlacklist";

function HeroServiceSlider() {
  return (
    <section id="heroService">
      <Carousel indicators={true} interval={3000}>
        <Carousel.Item>
          <div className="cstSlider">
            <TopVendorBlackLists image={Sofamakeoverandclean} />
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="cstSlider">
            <TopVendorBlackLists image={Documentation} />
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="cstSlider">
            <TopVendorBlackLists image={InvisibleGrill} />
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="cstSlider">
            <TopVendorBlackLists image={EnfieldService} />
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="cstSlider">
            <TopVendorBlackLists image={Laptopupgrade2} />
          </div>
        </Carousel.Item>
      </Carousel>
    </section>
  );
}
export default HeroServiceSlider;
