// src/components/NotFound.js
import React from "react";
import "./NotFound.css"; // Link to your external CSS
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="not-found-container">
      <div className="not-found-content">
        <h1 className="not-found-title">404</h1>
        <p className="not-found-text">
          Oops! The page you're looking for can't be found.
        </p>
        <div
          className="not-found-link"
          onClick={() => {
            navigate("/");
          }}
        >
          Go back home
        </div>
      </div>
    </div>
  );
};

export default NotFound;
