import React, { useState } from "react";
import { FaEdit } from "react-icons/fa";
import { FaDeleteLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import "./EmergencyCard.css";
import { DeleteEmergency } from "../../api/api";
import ConfirmModal from "../confirmToDeleteModal/ComfirmToDeleteModal";

const EmergencyCard = ({ emergency, onDelete }) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const handleDelete = async (id, areaId) => {
    try {
      const response = await DeleteEmergency(areaId, id);
      if (response.success) {
        alert("Emergency contact deleted successfully!");
        onDelete();
      } else {
        console.log("respone", response);
        alert("Emergency contact deleted successfully!");
        onDelete();
      }
    } catch (error) {
      console.error("Error deleting emergency contact:", error);
      alert("An error occurred. Please try again.");
    }
  };
  const handleConfirmDelete = () => {
    handleDelete(emergency._id, emergency.areaId);
    setShowModal(false);
  };
  const handleEditClick = (emergency) => {
    navigate("/dashboard/emergency/emergencyAdd", { state: { emergency } });
  };
  return (
    <div className="emergency-container">
      <div className="emergency-content">
        <img
          src={emergency.imageURL}
          alt="Emergency Contact"
          className="emergency-image"
        />
        <div className="emergency-info">
          <p className="emergency-area"> Name: {emergency.contactName}</p>
          <p className="emergency-area">Area: {emergency.area}</p>
          <p className="emergency-type">Type: {emergency.type}</p>
          <p className="emergency-type">Contact: {emergency.contactNumber}</p>
        </div>

        <div className="emergency-buttons">
          <button className="delete-button" onClick={() => setShowModal(true)}>
            <FaDeleteLeft />
          </button>
          <button
            className="edit-button"
            onClick={() => handleEditClick(emergency)}
          >
            <FaEdit />
          </button>
        </div>
      </div>
      <ConfirmModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        handleConfirm={handleConfirmDelete}
        text={"Emergency Contact"}
      />
    </div>
  );
};

export default EmergencyCard;
