import React, { useState, useContext } from "react";
import logo from "../../assets/images/logo.png";
import "./Header.css";
import closeIcon from "../../assets/images/closeIcon.png";
import menuIcon from "../../assets/images/menuIcon.png";
import drive from "../../assets/images/fluent_mail-alert-32-light.svg";
import { URL } from "../../env";
import { NavLink, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import diwaliToran from "../../assets/images/Untitled design (83).gif";
import defaultAvatar from "../../assets/images/defaultIocn.svg";

const Header = ({ openModal }) => {
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();
  const { user, isLoading } = useContext(AuthContext);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    document.body.style.overflow = !isOpen ? "hidden" : "auto";
  };
  const closeMenu = () => {
    setIsOpen(false);
    document.body.style.overflow = "auto";
  };
  const openCommingSoonModalModal = () => {
    setIsOpen(false);
    openModal();
  };

  return (
    <header>
      <div className="logo">
        <img
          src={logo}
          alt="iLaqa Logo"
          onClick={() => {
            navigate("/");
          }}
        />
        <div className="ilaqa-tagline-section">
          <span className="ilaqa-section">iLaqa</span>
          <span className="ilaqa-tagline">Your Supportive neighbourhood </span>
        </div>
      </div>
      <div className="right-nav">
        {/* <button
          onClick={() => {
            navigate("/aboutus");
          }}
          className="aboutus-btn"
          style={{
            backgroundColor: "white",
            color: "#0c60ca",
            border: "none",
            fontWeight: "400",
            fontSize: "20px",
            paddingBlock: "2px",
            paddingInline: "30px",
          }}
        >
          About Us
        </button> */}
        {/* <div
          className="hamburger"
          onClick={() => {
            navigate("/drives");
          }}
        >
          <img
            src={drive} // Provide a default image URL here
            alt="drive"
            style={{
              width: "40px",
              height: "30px",
              objectFit: "contain",
              borderRadius: "50%",
              marginRight: "10px",
            }}
            className="drive-icon"
          />
        </div> */}
        <div
          style={{
            height: "40px",
            minWidth: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {!isLoading && (
            <div>
              {user !== null ? (
                user.avatar === undefined ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "baseline",
                      gap: "5px",
                    }}
                  >
                    {user?.fullName && (
                      <span className="hello-user-nav">
                        Hello <br />
                        {user?.fullName}
                      </span>
                    )}
                    <div
                      className="hamburger"
                      onClick={() => {
                        navigate("/profile");
                      }}
                      style={{
                        width: "40px",
                        height: "40px",
                      }}
                    >
                      <img
                        src={defaultAvatar} // Provide a default image URL here
                        alt="avatar"
                        style={{
                          width: "40px",
                          height: "40px",
                          objectFit: "contain",
                          borderRadius: "50%",
                          marginRight: "10px",
                          border: "1px solid #0c60ca",
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "baseline",
                      gap: "5px",
                    }}
                  >
                    {user?.fullName && (
                      <span className="hello-user-nav">
                        Hello <br />
                        {user?.fullName}
                      </span>
                    )}
                    <div
                      className="hamburger"
                      onClick={() => {
                        navigate("/profile");
                      }}
                    >
                      <img
                        src={`${URL}/blacklist/image/${user.avatar}`}
                        alt="avatar"
                        style={{
                          width: "40px",
                          height: "40px",
                          objectFit: "cover",
                          borderRadius: "50%",
                          marginRight: "10px",
                          border: "1px solid #0c60ca",
                        }}
                      />
                    </div>
                  </div>
                )
              ) : (
                <button
                  onClick={() => {
                    navigate("/signin");
                  }}
                  style={{
                    backgroundColor: "white",
                    color: "#0c60ca",
                    border: "1px solid #407bff",
                    borderRadius: "10px",
                    fontWeight: "400",
                  }}
                  className="login-btn-header"
                >
                  Log in
                </button>
              )}
            </div>
          )}
        </div>

        <div
          style={{
            width: "36px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            onClick={toggleMenu}
            src={isOpen ? closeIcon : menuIcon}
            alt="Menu Icon"
            className={`${!isOpen ? "hamburger" : "menuclose"}`}
          />
        </div>
      </div>
      <nav className={`nav ${isOpen ? "open" : "close"}`}>
        <ul>
          <li>
            <NavLink
              to="/"
              className={({ isActive }) =>
                `menu-item no-arrow ${isActive ? "active" : ""}`
              }
              onClick={closeMenu}
            >
              <span className="text">Home</span>
            </NavLink>
          </li>
          <li onClick={openCommingSoonModalModal}>
            <a href="#" className="menu-item">
              <span style={{ color: "#0C60CA" }} className="text">
                My iLaqa
              </span>
            </a>
          </li>
          <li>
            <NavLink
              to="/services"
              className={({ isActive }) =>
                `menu-item ${isActive ? "active" : ""}`
              }
              onClick={closeMenu}
            >
              <span className="text">Services</span>
            </NavLink>
          </li>

          {/* <li style={{ position: "relative" }}>
    <NavLink
      to="/diwali2024"
      className={({ isActive }) =>
        `menu-item ${isActive ? "active" : ""}`
      }
      onClick={closeMenu}
    >
      <span className="text gradient-text" style={{ color: "" }}>
        Diwali Sale
      </span>
      <img
        src={diwaliToran}
        style={{
          width: "100px",
          marginLeft: "40px",
          position: "absolute",
          top: "-15px",
        }}
      />
    </NavLink>
  </li> */}
          <li>
            <NavLink
              to="/emergency"
              className={({ isActive }) =>
                `menu-item ${isActive ? "active" : ""}`
              }
              onClick={closeMenu}
            >
              <span className="text">Emergency</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/shops"
              className={({ isActive }) =>
                `menu-item ${isActive ? "active" : ""}`
              }
              onClick={closeMenu}
            >
              <span className="text">Shops Near You</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/vendor-blacklist"
              className={({ isActive }) =>
                `menu-item ${isActive ? "active" : ""}`
              }
              onClick={closeMenu}
            >
              <span className="text">Vendor Blacklist</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/aboutus"
              className={({ isActive }) =>
                `menu-item ${isActive ? "active" : ""}`
              }
            >
              <span className="text">About Us</span>
            </NavLink>
          </li>
          {(user?.role === "admin" || user?.role === "superadmin") && (
            <li>
              <NavLink to="/dashboard" className="menu-item">
                <span className="text">Dashboard</span>
              </NavLink>
            </li>
          )}

          <li className="contact-us">
            <a
              href="https://wa.me/+917032512444?text=Hello iLaqa"
              className="menu-item"
            >
              <span className="text" style={{ alignItems: "end" }}>
                Contact Us
              </span>
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
