import { URL } from "../env";

export const GetAreaNames = async () => {
  const res = await fetch(`${URL}/areas`);
  const data = await res.json();

  return data;
};

export const GetBuildingNames = async (areaId) => {
  const res = await fetch(`${URL}/areas/${areaId}/buildings`);
  const data = await res.json();

  return data;
};
export const GetClusterNames = async (areaId) => {
  const res = await fetch(`${URL}/shops/${areaId}/clusters`);
  const data = await res.json();
  console.log("cluster data ", data);
  return data;
};

export const getShops = async () => {
  const res = await fetch(`${URL}/shops/get`);
  const data = await res.json();
  return data;
};

export const getShopsArea = async () => {
  const res = await fetch(`${URL}/shops/areas-with-shops`);
  const data = await res.json();
  return data;
};
export const GetServices = async () => {
  const res = await fetch(`${URL}/los/get`);
  const data = await res.json();
  return data;
};
export const GetWhatsappLink = async (area, building) => {
  const res = await fetch(
    `${URL}/areas/${area}/buildings/${building}/whatsapp-link`
  );
  const data = await res.json();

  return data;
};

export const diwaliOrderSubmit = async (
  fullName,
  phone,
  email,
  referredPhone,
  address,
  products,
  grandTotal
) => {
  try {
    const response = await fetch(`${URL}/diwali/order`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fullName,
        phone,
        email,
        referredPhone,
        address,
        products,
        grandTotal,
      }),
    });

    if (!response.ok) {
      throw new Error("Failed to submit order. Please try again.");
    }

    const data = await response.json();

    return data; // Return the order ID
  } catch (error) {
    console.error(error);
    throw error; // Re-throw the error for handling in the calling function
  }
};

export const PostUserDetails = async (
  name,
  phNum,
  area,
  building,
  location
) => {
  return await fetch(`${URL}/users`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username: name,
      phone: phNum,
      areaName: area,
      buildingName: building,
      source: location,
    }),
  });
};

//admin
export const GetData = async () => {
  const res = await fetch(`${URL}/clusters/data`);
  const data = await res.json();

  return data;
};

export const GetClusters = async (areaName) => {
  const res = await fetch(`${URL}/clusters/${areaName}/clusters`);
  const data = await res.json();

  return data;
};
export const PostCluster = async (
  areaName,
  clusterName,
  buildings,
  whatsappLink,
  descri
) => {
  return await fetch(`${URL}/clusters/${areaName}/clusters`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name: clusterName,
      description: descri,
      whatsappLinks: [whatsappLink],
      activeLinkIndex: 0,
      buildings: buildings,
    }),
  });
};
export const PostArea = async (
  areaName,
  clusterName,
  whatsappLink,
  buildings,
  descri
) => {
  return await fetch(`${URL}/clusters/area`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      areaName,
      cluster: {
        name: clusterName,
        description: descri,
        whatsappLinks: [whatsappLink],
        activeLinkIndex: 0,
        buildings,
      },
    }),
  });
};

export const PostEditedCluster = async (
  clusterId,
  areaName,
  newClusterName,
  whatsappLink,
  buildings,
  descri
) => {
  return await fetch(`${URL}/clusters/${areaName}/clusters/${clusterId}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name: newClusterName,
      description: descri,
      buildings: buildings,
      whatsappLink: whatsappLink,
    }),
  });
};
export const DeleteCluster = async (areaname, clusterid) => {
  const res = await fetch(`${URL}/clusters/${areaname}/clusters/${clusterid}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  const data = await res.json();

  return data;
};
export const SubmitShopData = async (formData) => {
  try {
    console.log("hi");
    const response = await fetch(`${URL}/shops/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error submitting form:", error);
    throw error;
  }
};
export const SubmitEmergencyData = async (formData, areaName) => {
  try {
    console.log("hi");
    const response = await fetch(
      `${URL}/emergencycontact/${areaName}/emergency-contacts`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error submitting form:", error);
    throw error;
  }
};

export const GetEmergencyData = async () => {
  const res = await fetch(`${URL}/emergencycontact/emergency-contacts`);
  return await res.json();
};
export const GetVendorBlacklists = async () => {
  const res = await fetch(`${URL}/blacklist/vendor`);
  return await res.json();
};
export const PostVendor = async (formData) => {
  return await fetch(`${URL}/blacklist`, {
    method: "POST",
    body: formData,
  });
};

export const DeleteVendor = async (vendorid) => {
  const res = await fetch(`${URL}/blacklist/${vendorid}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  const data = await res.json();

  return data;
};
export const UpdateEmergency = async (id, areaId, formUpdateData) => {
  try {
    const url = `${URL}/emergencycontact/${areaId}/emergency-contacts/${id}`;
    console.log("api, Url", url);
    const res = await fetch(url, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formUpdateData),
    });

    if (!res.ok) {
      throw new Error(`Failed to update emergency contact: ${res.statusText}`);
    }

    const result = await res.json();
    return result; // Return the result of the update
  } catch (error) {
    console.error("Error in UpdateEmergency:", error);
    throw error; // Rethrow the error for further handling
  }
};

export const DeleteEmergency = async (areaId, emergencyId) => {
  const url = `${URL}/emergencycontact/${areaId}/emergency-contacts/${emergencyId}`;
  console.log("delete api url ", url);
  try {
    const res = await fetch(url, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error in DeleteEmergency API:", error);
    throw error;
  }
};
export const UpdateShop = async (formUpdateData) => {
  try {
    const res = await fetch(`${URL}/shops/edit`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formUpdateData),
    });

    if (!res.ok) {
      throw new Error(`Failed to update shop: ${res.statusText}`);
    }

    const result = await res.json();
    return result;
  } catch (error) {
    console.error("Error in UpdateShop:", error);
    throw error;
  }
};

export const DeleteShop = async (areaId, clusterId, shopId) => {
  const url = `${URL}/shops/${areaId}/clusters/${clusterId}/shops/${shopId}`;
  console.log("delete shop api url ", url);
  try {
    const res = await fetch(url, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error in DeleteShop API:", error);
    throw error;
  }
};

export const PostService = async (formData) => {
  return await fetch(`${URL}/los/add`, {
    method: "POST",

    body: formData,
  });
};

export const EditService = async (
  TypeId,
  serviceId,
  serviceName,
  description,
  subService
) => {
  return await fetch(`${URL}/los/${TypeId}/services/${serviceId}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      serviceName,
      description,
      subService,
    }),
  });
};

export const DeleteService = async (typeId, serviceId) => {
  const res = await fetch(`${URL}/los/${typeId}/services/${serviceId}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  const data = await res.json();

  return data;
};
export const GetServiceByType = async (type) => {
  const res = await fetch(`${URL}/los/type/${type}`);
  return await res.json();
};
export const GetServiceByName = async (name, type) => {
  const res = await fetch(`${URL}/los/type/${type}/service/${name}`);
  return await res.json();
};
export const PostReferralDetails = async (
  referrerName,
  referrerPhone,
  refereePhone,
  serviceName,
  serviceCost
) => {
  const { ilaqaAccessToken: accessToken } = JSON.parse(
    localStorage.getItem("ilaqaAuthDetails")
  );

  let response = await fetch(`${URL}/admin/add-referral`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      referrerName,
      referrerPhone,
      refereePhone,
      serviceName,
      serviceCost,
    }),
  });
  return response;
};

export const signUp = async (userDetails) => {
  try {
    const response = await fetch(
      "https://cognito-idp.ap-south-1.amazonaws.com",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-amz-json-1.1",
          "X-Amz-Target": "AWSCognitoIdentityProviderService.SignUp",
        },
        body: JSON.stringify(userDetails),
      }
    );

    return response;
  } catch (error) {
    console.error("Error signing up:", error);
  }
};

export const signIn = async (userDetails) => {
  const response = await fetch("https://cognito-idp.ap-south-1.amazonaws.com", {
    method: "POST",
    headers: {
      "Content-Type": "application/x-amz-json-1.1",
      "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
    },
    body: JSON.stringify(userDetails),
  });
  return response;
};
export const verifySignIn = async (userDetails) => {
  const response = await fetch("https://cognito-idp.ap-south-1.amazonaws.com", {
    method: "POST",
    headers: {
      "Content-Type": "application/x-amz-json-1.1",
      "X-Amz-Target":
        "AWSCognitoIdentityProviderService.RespondToAuthChallenge",
    },
    body: JSON.stringify(userDetails),
  });
  return response;
};

export const GetAccessToken = async () => {
  const { ilaqaRefreshToken } = JSON.parse(
    localStorage.getItem("ilaqaAuthDetails")
  );
  const details = {
    AuthFlow: "REFRESH_TOKEN_AUTH",
    ClientId: "7hiv233uq3q0acio48kh2rdeip",
    AuthParameters: {
      REFRESH_TOKEN: ilaqaRefreshToken,
    },
  };
  const response = await fetch("https://cognito-idp.ap-south-1.amazonaws.com", {
    method: "POST",
    headers: {
      "Content-Type": "application/x-amz-json-1.1",
      "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
    },
    body: JSON.stringify(details),
  });
  return response;
};
// Fetch user profile by mobile number

export const GetReferralData = async () => {
  const { ilaqaAccessToken } = JSON.parse(
    localStorage.getItem("ilaqaAuthDetails")
  );

  const res = await fetch(`${URL}/admin/referrals`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${ilaqaAccessToken}`,
    },
  });
  return await res.json();
};

export const getUserDetailsByPhoneNo = async (phoneNo) => {
  const res = await fetch(`${URL}/users/get/${phoneNo}`);
  const data = await res.json();
  return data;
};

export const getUserDetailsByAccessToken = async (accessToken) => {
  const res = await fetch(`${URL}/users/get/${accessToken}`);
  const data = await res.json();
  return data;
};
export const fetchUserData = async (number, token) => {
  const response = await fetch(`${URL}/users/get/${number}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const createProfile = async (mobile, token) => {
  const { ilaqaAccessToken } = JSON.parse(
    localStorage.getItem("ilaqaAuthDetails")
  );

  return await fetch(`${URL}/users/profile/${mobile}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${ilaqaAccessToken}`,
    },
  });
};
export const SubmitFormData = async (formData) => {
  const { ilaqaAccessToken } = JSON.parse(
    localStorage.getItem("ilaqaAuthDetails")
  );

  const response = await fetch(`${URL}/users/update-profile`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${ilaqaAccessToken}`,
    },
    body: formData,
  });
  return response;
};

export const CheckUserByNumber = async (number) => {
  const response = await fetch(`${URL}/shops/check-user`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ mobile: number }),
  });

  return await response.json();
};

export const editUserProfile = async (formData) => {
  const { ilaqaAccessToken: accessToken } = JSON.parse(
    localStorage.getItem("ilaqaAuthDetails")
  );

  try {
    const response = await fetch(`${URL}/users/update-profile`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error("Failed to update profile");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error updating profile:", error);
    throw error;
  }
};

//api for redemption request by user//
export const requestRedemption = async (id, status, email) => {
  const { ilaqaAccessToken } = JSON.parse(
    localStorage.getItem("ilaqaAuthDetails")
  );

  const response = await fetch(`${URL}/users/request-redemption`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${ilaqaAccessToken}`,
    },
    body: JSON.stringify({
      userId: id,
      usePreferredEmail: status,

      preferredEmail: email,
    }),
  });

  const responseData = await response.json();
  return responseData;
};
export const GetRedemptionRequests = async () => {
  const { ilaqaAccessToken } = JSON.parse(
    localStorage.getItem("ilaqaAuthDetails")
  );

  try {
    const response = await fetch(`${URL}/admin/redemption-requests`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",

        Authorization: `Bearer ${ilaqaAccessToken}`,
      },
    });

    // Check if the response is ok (status in the range 200-299)
    if (!response.ok) {
      throw new Error(`Error: ${response.status} - ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Failed to fetch redemption requests:", error);
    // Optionally return null or an error message depending on your use case
    return null;
  }
};
export const GetLatestRedemptionRequests = async (id, limit) => {
  const { ilaqaAccessToken } = JSON.parse(
    localStorage.getItem("ilaqaAuthDetails")
  );

  try {
    const response = await fetch(
      `${URL}/admin/redemption-requests?userId=${id}&limt=${limit}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",

          Authorization: `Bearer ${ilaqaAccessToken}`,
        },
      }
    );

    // Check if the response is ok (status in the range 200-299)
    if (!response.ok) {
      throw new Error(`Error: ${response.status} - ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Failed to fetch redemption requests:", error);
    // Optionally return null or an error message depending on your use case
    return null;
  }
};
export const GetUserRedemptionRequests = async (id) => {
  const { ilaqaAccessToken } = JSON.parse(
    localStorage.getItem("ilaqaAuthDetails")
  );

  try {
    const response = await fetch(
      `${URL}/admin/redemption-requests?userId=${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",

          Authorization: `Bearer ${ilaqaAccessToken}`,
        },
      }
    );

    // Check if the response is ok (status in the range 200-299)
    if (!response.ok) {
      throw new Error(`Error: ${response.status} - ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Failed to fetch redemption requests:", error);
    // Optionally return null or an error message depending on your use case
    return null;
  }
};
export const PostVoucherDetails = async (formData, id) => {
  const { ilaqaAccessToken } = JSON.parse(
    localStorage.getItem("ilaqaAuthDetails")
  );

  const response = await fetch(`${URL}/admin/redemption-requests/${id}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${ilaqaAccessToken}`,
    },
    body: formData,
  });
  return response;
};
export const updateReferralStatus = async (id, status) => {
  const { ilaqaAccessToken } = JSON.parse(
    localStorage.getItem("ilaqaAuthDetails")
  );

  const response = await fetch(
    `${URL}/superadmin/update-referral-status/${id}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ilaqaAccessToken}`,
      },
      body: JSON.stringify({ status: status }),
    }
  );
};
export const PostTracking = async (areaName, societyname, location) => {
  return await fetch(`${URL}/sourcename/submit/`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      areaName: areaName,
      societyName: societyname,
      source: location,
    }),
  });
};

// api.js
export const BookServiceForUser = async (
  username,
  phone,
  serviceName,
  serviceDescription
) => {
  return await fetch(`${URL}/los/book-service`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username,
      phone,
      serviceName,
      serviceDescription,
    }),
  });
};

export const UpdateTrackingApi = async (source) => {
  return await fetch(`${URL}/source/get`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      source: source,
    }),
  });
};

export const FetchDiwaliOrderData = async () => {
  const res = await fetch(`${URL}/diwali/order`);
  const data = await res.json();
  return data;
};

export const updateDiwaliOrder = async (status, isCancelled, orderId) => {
  const res = await fetch(`${URL}/diwali/order/${orderId}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      status,
      isCancelled,
    }),
  });
  const data = await res.json();
  return data;
};
export const updateClaimStatus = async (id, status) => {
  const { ilaqaAccessToken: accessToken } = JSON.parse(
    localStorage.getItem("ilaqaAuthDetails")
  );

  const res = await fetch(`${URL}/users/${id}/claim-status`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      claimStatus: status,
    }),
  });
  const data = await res.json();
  return data;
};

export const CreateOrder = async (data) => {
  const res = await fetch(`${URL}/payments/create-order`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const data1 = await res.json();
  return data1;
};
export const VerifyPayment = async (data) => {
  const res = await fetch(`${URL}/payments/verify-payment`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const data1 = await res.json();
  return data1;
};

export const sendWhatsappMessage = async (mobile, name) => {
  const authToken =
    "QUNkNTZlNGZjMzIwMjcxYWY2YWE2MzhjY2U4MDQ4NDk4ODpkYmY2ZDMwNmVhMzVkYTNjYjA1ZTkwZjc3YTc4MWQ2Nw";

  try {
    const response = await fetch(
      "https://api.twilio.com/2010-04-01/Accounts/ACd56e4fc320271af6aa638cce80484988/Messages.json",
      {
        method: "POST",
        headers: {
          Authorization: `Basic ${authToken}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          From: "whatsapp:916309483600",
          To: `whatsapp:91${mobile}`,
          ContentVariables: JSON.stringify({ 1: name }),
          ContentSid: "HX50b5e25ec636d53b65ae3acca058db1e",
        }),
      }
    );

    if (!response.ok) {
      throw new Error(`Error: ${response.status} - ${response.statusText}`);
    }

    const data = await response.json();
    console.log("Message sent successfully:", data);
  } catch (error) {
    console.error("Error sending message:", error.message);
  }
};

export const diwaliAlaCarteOrderSubmit = async (
  fullName,
  phone,
  email,
  referredPhone,
  address,
  products,
  grandTotal
) => {
  try {
    const response = await fetch(`${URL}/alacarte/order`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fullName,
        phone,
        email,
        referredPhone,
        address,
        products,
        grandTotal,
      }),
    });

    if (!response.ok) {
      throw new Error("Failed to submit order. Please try again.");
    }

    const data = await response.json();

    return data; // Return the order ID
  } catch (error) {
    console.error(error);
    throw error; // Re-throw the error for handling in the calling function
  }
};

export const PostNewDrive = async (formData) => {
  let arr = formData.societies.map((society) => society.value);
  try {
    const response = await fetch(`${URL}/eventdrive/event/post`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: formData.driveName,
        description: formData.driveDescription,
        start_date: formData.startsOn,
        end_date: formData.endsOn,

        location: formData.locationLink,
        societies: arr,
      }),
    });

    if (!response.ok) {
      throw new Error("Failed to submit order. Please try again.");
    }

    const data = await response.json();

    return data; // Return the order ID
  } catch (error) {
    console.error(error);
    throw error; // Re-throw the error for handling in the calling function
  }
};
export const FetchAllDrives = async () => {
  const res = await fetch(`${URL}/eventdrive/get/all`);
  const data = await res.json();
  return data;
};
export const deleteDrive = async (id) => {
  const res = await fetch(`${URL}/eventdrive/${id}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  const data = await res.json();
  return data;
};
export const PatchNewDrive = async (formData, id) => {
  let arr = formData.societies.map((society) => society.value);
  try {
    const response = await fetch(`${URL}/eventdrive/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: formData.driveName,
        description: formData.driveDescription,
        start_date: formData.startsOn,
        end_date: formData.endsOn,

        location: formData.locationLink,
        societies: arr,
      }),
    });

    if (!response.ok) {
      throw new Error("Failed to submit order. Please try again.");
    }

    const data = await response.json();

    return data; // Return the order ID
  } catch (error) {
    console.error(error);
    throw error; // Re-throw the error for handling in the calling function
  }
};
export const FetchAllBuildings = async () => {
  const res = await fetch(`${URL}/areas/buildings`);
  const data = await res.json();
  return data;
};
export const FetchDrivesByArea = async (area) => {
  const res = await fetch(`${URL}/eventdrive/drives-by-area/${area}`);
  const data = await res.json();
  return data;
};
