import React, { useState, useEffect } from "react";
import "./Shops.css";
import TopVendorBlackLists from "../../components/TopSectionVendorBlacklist/TopVendorBlacklist";
import shopImage from "../../../src/assets/images/ShopIllutration.svg";
import filterIcon from "../../assets/images/filterIcon.svg";
import ShopCard from "../../components/ShopCard/ShopCard";
import ShopModal from "../../components/ShopViewCard/ShopModal";
import { getShops } from "../../api/api";
import ShopSearchBar from "../../components/SearchBar/Shops/ShopSearchBar";
import GenralLoader from "../../components/Loader/GenralLoader";
import ShopFilterModal from "../../components/ShopFilterModal/ShopFilterModal";

function Shops() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedShop, setSelectedShop] = useState(false);
  const [shopData, setShopData] = useState([]);
  const [filteredShopData, setFilteredShopData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [uniqureArea, setuniqueArea] = useState([]);
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handleShopClick = (shop) => {
    setSelectedShop(shop);
    setIsModalOpen(true);
  };
  const getShop = async () => {
    try {
      const data = await getShops();
      console.log("shopdata for shop", data);
      setShopData(data);
      const areaNames = data.map((shop) => shop.areaName.trim());
      const uniqueAreas = [...new Set(areaNames)];
      setuniqueArea(uniqueAreas);
      setFilteredShopData(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching shops:", error);
      setLoading(false);
    }
  };
  const closeFilterModal = () => {
    setIsFilterModalOpen(false);
  };
  const handleAreaSelect = (area) => {
    if (area === "") {
      setFilteredShopData(shopData);
    } else {
      const trimmedArea = area.trim().toLowerCase();
      const filtered = shopData.filter(
        (shop) => shop.areaName.trim().toLowerCase() === trimmedArea
      );
      setFilteredShopData(filtered);
    }
  };

  useEffect(() => {
    getShop();
  }, []);
  const handleSearch = (query) => {
    setSearchQuery(query);
    const lowercasedQuery = query.toLowerCase();
    if (lowercasedQuery) {
      const filteredData = shopData.filter((shop) => {
        return (
          shop.shopName.toLowerCase().includes(lowercasedQuery) ||
          shop.Address.toLowerCase().includes(lowercasedQuery) ||
          shop.shopDescription.toLowerCase().includes(lowercasedQuery) ||
          shop.openTime.toLowerCase().includes(lowercasedQuery) ||
          shop.closeTime.toLowerCase().includes(lowercasedQuery) ||
          shop.closeOn.some((day) =>
            day.toLowerCase().includes(lowercasedQuery)
          ) ||
          shop.tags.some((tag) => tag.toLowerCase().includes(lowercasedQuery))
        );
      });
      setFilteredShopData(filteredData);
    } else {
      setFilteredShopData(shopData);
    }
  };

  return (
    <div className="wrapper">
      <div className="vendor-blacklist-page">
        <TopVendorBlackLists image={shopImage} />
        {isModalOpen && <ShopModal onClose={closeModal} shop={selectedShop} />}
        <ShopSearchBar onSearch={handleSearch} searchQuery={searchQuery} />
        {isFilterModalOpen && (
          <ShopFilterModal
            closeModal={closeFilterModal}
            areas={uniqureArea}
            onAreaSelect={handleAreaSelect}
          />
        )}
        <div className="vendor-header">
          <h1 className="header-title">Shops Near You</h1>
          <img
            src={filterIcon}
            className="header-icon"
            alt="Filter Icon"
            onClick={() => setIsFilterModalOpen(true)}
          />
        </div>

        {loading ? (
          <GenralLoader />
        ) : (
          <>
            {filteredShopData.length > 0 ? (
              <ShopCard
                shops={filteredShopData}
                onShopClick={handleShopClick}
              />
            ) : (
              <span className="no-result-text">No result found</span>
            )}
          </>
        )}
      </div>
    </div>
  );
}
export default Shops;
