import React from "react";
import "./Footer.css";
import image from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="footer">
      <div className="footer-content">
        <div className="footer-logo">
          <img src={image} alt="Logo" />
        </div>
        <ul className="footer-nav">
          <li>
            <a
              onClick={() => {
                navigate("/aboutus");
              }}
            >
              About Us
            </a>
          </li>
          <li
            onClick={() => {
              navigate("/services");
            }}
          >
            Services
          </li>{" "}
          <li
            onClick={() => {
              navigate("/terms&conditions");
            }}
          >
            Terms and Conditions
          </li>
          {/* <li>Address</li>
          <li>Email</li>
          <li>FAQs</li> */}
        </ul>
      </div>
    </div>
  );
};

export default Footer;
