import React, { useContext, useRef, useState } from "react";
import "./SignIn.css";
import {
  CheckUserByNumber,
  createProfile,
  signIn,
  signUp,
  verifySignIn,
} from "../../api/api";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

import TermsAndConditions from "../../components/TermsAndConditions/TermsAndConditions";

function SignIn() {
  const { setUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [mobile, setMobile] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [otp, setOtp] = useState(new Array(4).fill(""));
  const [sessionToken, setSessionToken] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);

  const [isCorrectOtp, setIsCorrectOtp] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [tandcError, setTandCSetError] = useState("");
  const [isTermsandCond, setIsTermsandCond] = useState(false);

  const handleOtpChange = (element, index) => {
    const value = element.value;
    if (!isNaN(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (value && index < 4) {
        if (otpInputRefs.current[index + 1]) {
          otpInputRefs.current[index + 1].focus();
        }
      }
    }
    if (index === 3) {
      setIsCorrectOtp(false);
    }
  };
  const handleMobileChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 10) {
      setMobile(value);
      setMobileError(false);
    } else {
    }
  };

  const getOtpForSignIn = async () => {
    if (mobile.length === 10) {
      const status = await CheckUserByNumber(mobile);
      if (status.exists) {
        const userDetails = {
          AuthFlow: "CUSTOM_AUTH",
          ClientId: "7hiv233uq3q0acio48kh2rdeip",
          AuthParameters: {
            USERNAME: `+91${mobile}`,
            PASSWORD: "Password1!",
          },
        };

        const responseSignin = await signIn(userDetails);
        const resultSignin = await responseSignin.json();
        console.log(resultSignin);
        if (responseSignin.status === 200) {
          setSessionToken(resultSignin.Session);
          setIsOtpSent(true);
        }
      } else {
        const userDetails = {
          ClientId: "7hiv233uq3q0acio48kh2rdeip",
          Username: `+91${mobile}`,
          Password: "Password1!",
          UserAttributes: [
            {
              Name: "phone_number",
              Value: `+91${mobile}`,
            },
          ],
        };

        const responseSignup = await signUp(userDetails); // Existing flow
        const resultSignup = await responseSignup.json();
        console.log(responseSignup, resultSignup);
        if (
          (responseSignup.status === 200 &&
            resultSignup.UserConfirmed === true) ||
          (responseSignup.status === 400 &&
            resultSignup.message === "User already exists")
        ) {
          const userDetails = {
            AuthFlow: "CUSTOM_AUTH",
            ClientId: "7hiv233uq3q0acio48kh2rdeip",
            AuthParameters: {
              USERNAME: `+91${mobile}`,
              PASSWORD: "Password1!",
            },
          };

          const responseSignin1 = await signIn(userDetails);
          const resultSignin1 = await responseSignin1.json();

          if (responseSignin1.status === 200) {
            setSessionToken(resultSignin1.Session);

            setIsOtpSent(true);
          }
        }
      }
    } else {
      setMobileError(true);
    }
  };
  const handleVerifySignIn = async () => {
    if (!isChecked) {
      setTandCSetError(
        "You must agree to the terms and conditions to proceed."
      );
      return;
    }
    const otpString = otp.join("");

    const userDetails = {
      ClientId: "7hiv233uq3q0acio48kh2rdeip",
      ChallengeName: "CUSTOM_CHALLENGE",
      Session: `${sessionToken}`,
      ChallengeResponses: {
        USERNAME: `+91${mobile}`,
        ANSWER: `${otpString}`,
      },
    };
    const response = await verifySignIn(userDetails);
    const result = await response.json();

    if (response.status === 200) {
      if (result.Session) {
        setSessionToken(result.Session);

        setIsCorrectOtp(true);
        setOtp(new Array(4).fill(""));
        otpInputRefs.current[0]?.focus();
      } else {
        let ilaqaAuthDetails = {
          ilaqaAccessToken: result.AuthenticationResult.AccessToken,
          ilaqaPhNumber: `${mobile}`,
          ilaqaRefreshToken: result.AuthenticationResult.RefreshToken,
        };
        localStorage.setItem(
          "ilaqaAuthDetails",
          JSON.stringify(ilaqaAuthDetails)
        );

        const responseProfile = await createProfile(
          `${mobile}`,
          result.AuthenticationResult.AccessToken
        );

        const user = await responseProfile.json();
        if (responseProfile.status === 404) {
          setUser(user.user);
          navigate("/register");
        } else if (
          (responseProfile.status === 200 && user.email === undefined) ||
          user.fullName === undefined
        ) {
          setUser(user);
          navigate("/register");
        } else if (responseProfile.status === 200) {
          setUser(user);
          navigate("/dashboard");
        }
      }
    }
  };
  const otpInputRefs = useRef([]);
  const isOtpComplete = otp.every((value) => value !== "");

  return (
    <div className="auth-container-wrapper">
      <div className="auth-container">
        <div
          style={{
            fontSize: "32px",
            color: "#0c60ca",
            fontWeight: "700",
            textAlign: "center",
            marginBlock: "8px",
          }}
        >
          Login
        </div>

        <div className={`mobile-number-section ${isOtpSent && "disable-div"}`}>
          {" "}
          <h3 className="mobile-number-section-heading">
            Enter your <span style={{ color: "#DF9B28" }}>mobile </span>
            number
          </h3>
          <input
            type="tel"
            value={mobile}
            onChange={handleMobileChange}
            placeholder="Enter your mobile number"
            className="mobile-number-section-input"
          />
          {mobileError && (
            <p className="errormessage">
              Please enter 10 digit valid phone number
            </p>
          )}
          <div className="mobile-number-section-button-wrapper">
            <button
              className={`mobile-number-section-button `}
              onClick={() => {
                getOtpForSignIn();
              }}
              style={{ opacity: mobile.length !== 10 ? 0.8 : 1 }}
            >
              Get OTP
            </button>
          </div>
        </div>
        <div className={`otp-section ${!isOtpSent && "disable-div"}`}>
          <div className={``}>
            <h3 className="enter-otp">Enter OTP</h3>
            {isCorrectOtp && (
              <h3
                style={{ fontSize: "12px", fontWeight: 400, color: "#FF0000" }}
              >
                The code you entered is incorrect
              </h3>
            )}
            <div className="otp-boxes">
              {otp.map((value, index) => (
                <input
                  style={{
                    width: "40px",
                    height: "40px",
                    color: "black",
                    border: "1px solid #0C60CA",
                    textAlign: "center",
                  }}
                  key={index}
                  value={value}
                  type="text"
                  maxLength="1"
                  ref={(el) => (otpInputRefs.current[index] = el)}
                  //value={value}
                  onChange={(e) => handleOtpChange(e.target, index)}
                  onKeyDown={(e) => {
                    if (e.key === "Backspace" && index > 0 && !value) {
                      otpInputRefs.current[index - 1].focus();
                    }
                  }}
                />
              ))}
            </div>
            <p
              style={{
                textAlign: "left",
                marginTop: "5px",
                fontSize: "12px",
                fontWeight: "600",
              }}
            >
              Didn't Receive OTP?{" "}
              <a
                href="#"
                className="resend-link"
                onClick={() => {
                  getOtpForSignIn();
                  setOtp(new Array(4).fill(""));
                  setIsCorrectOtp(false);
                }}
              >
                Resend
              </a>
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                marginBlock: "10px",
              }}
            >
              <input
                type="checkbox"
                id="terms"
                checked={isChecked}
                onChange={() => {
                  setIsChecked(!isChecked);
                  setTandCSetError(false);
                }}
                style={{ width: "20px", minWidth: "5px" }}
              />
              <label
                htmlFor="terms"
                style={{
                  textAlign: "left",
                  color: "black",
                  fontSize: "12px",
                  fontWeight: "600",
                  flexGrow: 1,
                  margin: 0,
                }}
              >
                I agree to the{" "}
                <span
                  onClick={() => {
                    setIsTermsandCond(true);
                  }}
                  style={{ textDecoration: "underline", color: "#0c60ca" }}
                >
                  Terms and Conditions
                </span>
              </label>
            </div>

            {tandcError && (
              <p
                style={{
                  padding: "5px",
                  fontSize: "12px",
                  fontWeight: 400,
                  color: "#EBA7B8",
                  textAlign: "left",
                  // border: "1px solid #F3D4D7",
                  backgroundColor: "#FAE5E5",
                }}
              >
                {tandcError}
              </p>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button
                disabled={!isOtpComplete}
                onClick={() => {
                  handleVerifySignIn();
                }}
                style={{ opacity: !isOtpComplete ? 0.5 : 1 }}
                className="verify-btn"
              >
                Verify
              </button>
            </div>
          </div>
        </div>
        {isTermsandCond && (
          <TermsAndConditions setIsTermsandCond={setIsTermsandCond} />
        )}
      </div>
    </div>
  );
}

export default SignIn;
