import React from "react";
// import "./TermsAndConditions.css";

const Tandc = () => {
  return (
    <div className="terms-unique-container">
      <h1 className="terms-unique-title">Terms and Conditions</h1>
      <p className="terms-unique-updated">
        <strong>Last updated on Oct 17 2024</strong>
      </p>
      <p className="termspara">
        For the purpose of these Terms and Conditions, the term "we", "us",
        "our" used anywhere on this page shall mean ILAQA TECHNOLOGIES PRIVATE
        LIMITED, whose registered/operational office is 1102 Building number 5,
        MHADA Deluxe Apartment, Rambaug, Powai Mumbai MAHARASHTRA 400076. "You",
        "your", "user", "visitor" shall mean any natural or legal person who is
        visiting our website and/or agreed to purchase from us.
      </p>
      <h2 className="terms-unique-heading">General Terms</h2>
      <p className="termspara">
        Your use of the website and/or purchase from us are governed by the
        following Terms and Conditions.
      </p>
      <ul className="terms-unique-list">
        <li>
          The content of the pages of this website is subject to change without
          notice.
        </li>
        <li>
          Neither we nor any third parties provide any warranty or guarantee as
          to the accuracy, timeliness, performance, completeness, or suitability
          of the information and materials found on this website for any
          particular purpose.
        </li>
        <li>
          Your use of any information on this website is at your own risk, and
          we shall not be liable.
        </li>
      </ul>
      <h2 className="terms-unique-heading">Intellectual Property</h2>
      <p className="termspara">
        Our website contains material owned by or licensed to us, including
        design, layout, and graphics. Reproduction is prohibited without prior
        written consent.
      </p>
      <h2 className="terms-unique-heading">External Links</h2>
      <p className="termspara">
        From time to time, our website may include links to other websites for
        convenience. We are not responsible for the content of these linked
        websites.
      </p>
      <h2 className="terms-unique-heading">Disputes and Legal</h2>
      <p className="termspara">
        Any dispute arising from the use of our website or purchase from us is
        subject to the laws of India.
      </p>
      <p className="termspara">
        We shall be under no liability whatsoever in respect of any loss or
        damage arising directly or indirectly out of the decline of
        authorization for any Transaction on account of the Cardholder having
        exceeded the preset limit.
      </p>
    </div>
  );
};

export default Tandc;
