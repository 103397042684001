import React, { useContext, useEffect, useState } from "react";
import {
  GetReferralData,
  sendWhatsappMessage,
  updateReferralStatus,
} from "../../api/api";
import "./ReferralDashboard.css";

import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { BsSearch } from "react-icons/bs";
function ReferralDashboard() {
  const { user } = useContext(AuthContext);
  const [referralData, setReferralData] = useState();
  const [filteredData, setFilteredData] = useState();
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState("");
  const navigate = useNavigate();
  const getData = async () => {
    setLoading(true);
    console.log("hello");
    const res = await GetReferralData();
    setLoading(false);
    setReferralData(res);
    setFilteredData(res);
  };
  useEffect(() => {
    getData();
  }, []);
  const handleApprove = async (id, mobile, name) => {
    const approved = window.confirm("Approve this referral?");

    if (approved) {
      try {
        await updateReferralStatus(id, "Approved");
        await getData();
        await sendWhatsappMessage(mobile, name);
      } catch (error) {
        console.error("Error approving referral or opening WhatsApp:", error);
      }
    }
  };

  const handleDeny = async (id) => {
    const denied = window.confirm("Deny this referral?");

    if (denied) {
      await updateReferralStatus(id, "Denied");
      await getData();
    }
  };
  const handleSearchClick = () => {
    if (input === "") {
      setFilteredData(referralData);
      return;
    }
    const filterBySearch = referralData.filter((item) => {
      if (
        item.referrerPhone.toLowerCase().includes(input.toLowerCase()) ||
        item.status.toLowerCase().includes(input.toLowerCase())
      ) {
        return item;
      }
    });
    setFilteredData(filterBySearch);
  };
  useEffect(() => {
    handleSearchClick();
  }, [input]);
  if (loading) {
    return (
      <div style={{ textAlign: "center", fontWeight: "900" }}>Loading...</div>
    );
  }
  return (
    <div className="referral-cards-container">
      <button
        className="addReferralButton"
        onClick={() => {
          navigate("/dashboard/referral/referralForm");
        }}
      >
        Add New Referral Details
      </button>
      <button
        onClick={() => {
          navigate("/dashboard/referral/redemptionrequests");
        }}
        style={{ borderRadius: "40px" }}
      >
        {"View Redemption requests >>"}
      </button>
      <div className="search-container-admin">
        <input
          className="search-input-admin"
          style={{ border: "0" }}
          placeholder="Search by mobile number and status"
          onChange={(e) => {
            setInput(e.target.value);
          }}
        />
        <BsSearch />
      </div>
      {filteredData?.map((referral) => (
        <div key={referral._id} className="referral-card">
          <h3
            className="referral-card-title"
            style={{ textAlign: "center", fontWeight: "600" }}
          >
            {referral.serviceName}
          </h3>
          <p>
            <span style={{ fontWeight: "700" }}>Referrer Name:</span>{" "}
            {referral.referrerName}
          </p>
          <p>
            <span style={{ fontWeight: "700" }}>Referrer Phone:</span>{" "}
            {referral.referrerPhone}
          </p>
          <p>
            <span style={{ fontWeight: "700" }}>Referee Phone:</span>{" "}
            {referral.refereePhone}
          </p>
          <p>
            <span style={{ fontWeight: "700" }}>Service Cost:</span> ₹
            {referral.serviceCost}
          </p>
          <p>
            <span style={{ fontWeight: "700" }}>Reward Amount:</span> ₹
            {Math.round(referral.rewardAmount)}
          </p>
          <p>
            <span style={{ fontWeight: "700" }}>Status:</span>
            <span
              style={{
                backgroundColor:
                  referral.status === "Approved" ? "green" : "red",
                borderRadius: "20px",
                padding: "4px",
                color: "white",
                fontWeight: "700",
              }}
            >
              {referral.status}
            </span>
          </p>
          <div>
            {(referral.status === "Pending" ||
              referral.status === "Denied") && (
              <button
                className="approve-button"
                style={{
                  backgroundColor:
                    user.role === "admin" ? "#007bff55" : "#007bff",
                  margin: "10px",
                }}
                onClick={() =>
                  handleApprove(
                    referral._id,
                    referral.referrerPhone,
                    referral.referrerName
                  )
                }
                disabled={
                  referral.status === "Approved" || user.role === "admin"
                } // Disable if already approved
              >
                {referral.status === "Approved" ? "Approved" : "Approve"}
              </button>
            )}
            {referral.status === "Pending" && (
              <button
                className="approve-button"
                style={{
                  backgroundColor:
                    user.role === "admin" ? "#007bff55" : "#007bff",
                  margin: "10px",
                }}
                onClick={() => handleDeny(referral._id)}
                disabled={
                  referral.status === "Denied" ||
                  referral.status === "Approved" ||
                  user.role === "admin"
                } // Disable if already approved
              >
                {referral.status === "Denied" ? "Denied" : "Deny"}
              </button>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

export default ReferralDashboard;
