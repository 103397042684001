import React, { useState } from "react";
import "./VoucherDetailsForm.css"; // Import the CSS file
import { useLocation, useNavigate } from "react-router-dom";
import { PostVoucherDetails } from "../../api/api";

const VoucherDetailsForm = () => {
  const { state } = useLocation();

  const [formData, setFormData] = useState({
    screenshot: null,
    lastFourDigits: "",
    expiryDate: "",
  });
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const voucherFormData = new FormData();

    for (const item in formData) {
      voucherFormData.append(item, formData[item]);
    }
    await PostVoucherDetails(voucherFormData, state);

    navigate("/dashboard/referral/redemptionrequests");
  };

  return (
    <div className="voucher-form-wrapper">
      <h3 style={{ fontWeight: 600, fontSize: "20px", marginBlock: "30px" }}>
        Enter Voucher Details
      </h3>
      <form className="voucher-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="screenshot">Email Screenshot:</label>
          <input
            type="file"
            id="screenshot"
            name="screenshot"
            accept="image/*"
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor=" lastFourDigits">Voucher Card Last 4 Digits:</label>
          <input
            type="text"
            id=" lastFourDigits"
            name="lastFourDigits"
            maxLength="4"
            value={formData.voucherLastDigits}
            onChange={handleChange}
            required
            pattern="\d{4}"
            title="Please enter exactly 4 digits"
          />
        </div>

        <div className="form-group">
          <label htmlFor="expiryDate">Expiry Date:</label>
          <input
            type="date"
            id="expiryDate"
            name="expiryDate"
            value={formData.expiryDate}
            onChange={handleChange}
            required
          />
        </div>

        <button type="submit" className="submit-button">
          Submit
        </button>
        <button
          className="submit-button"
          onClick={() => {
            navigate("/dashboard/referral/redemptionrequests");
          }}
          style={{ marginTop: "15px", backgroundColor: "#ff000088" }}
        >
          Cancel
        </button>
      </form>
    </div>
  );
};

export default VoucherDetailsForm;
