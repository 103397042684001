import React, { useEffect, useState } from "react";
import "./DriveAdmin.css";
import { MdDelete } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import AddDriveModal from "./AddDriveModal";
import EditDriveModal from "./EditDriveModal";
import moment from "moment";
import { deleteDrive, FetchAllBuildings, FetchAllDrives } from "../../api/api";
function DriveAdmin() {
  const [isAddModal, setIsAddModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [buildings, setBuildings] = useState([]);
  const [drives, setDrives] = useState([]);
  const [selectedDrive, setSelectedDrive] = useState({});
  const fetchAllDrives = async () => {
    let data = await FetchAllDrives();
    setDrives(data);
  };
  const fetchAllBuildings = async () => {
    let data = await FetchAllBuildings();
    setBuildings(data);
  };
  useEffect(() => {
    fetchAllDrives();
    fetchAllBuildings();
  }, []);
  return (
    <div className="admin-los-card-container">
      <button
        className="los-admin-add-service"
        onClick={() => {
          setIsAddModal(true);
        }}
      >
        Add New Drive
      </button>
      <div className="admin-los-cards">
        {drives.map((d) => (
          <div key={d._id} className="admin-los-card">
            <div>
              <h3>{d?.name}</h3>
              <p>{d?.description}</p>
              <p>
                {moment(d?.start_date).format("YYYY-MM-DD")} to{" "}
                {moment(d.end_date).format("YYYY-MM-DD")}
              </p>
              {d.societies?.map((s) => (
                <span>{s}, </span>
              ))}
            </div>
            <div className="admin-los-card-buttons">
              <button
                className="admin-los-edit-button"
                onClick={() => {
                  setIsEditModal(true);
                  setSelectedDrive(d);
                }}
              >
                <FaRegEdit />
              </button>
              <button
                className="admin-los-delete-button"
                onClick={() => {
                  let res = window.confirm(
                    `Do you want to delete ${d?.name} ?`
                  );
                  if (res) {
                    const deleteDriveFunc = async () => {
                      await deleteDrive(d._id);
                      await fetchAllDrives();
                    };
                    deleteDriveFunc();
                  }
                }}
              >
                <MdDelete />
              </button>
            </div>
          </div>
        ))}
      </div>
      {isAddModal && (
        <AddDriveModal
          setIsAddModal={setIsAddModal}
          fetchAllDrives={fetchAllDrives}
          buildings={buildings}
        />
      )}
      {isEditModal && (
        <EditDriveModal
          setIsEditModal={setIsEditModal}
          drive={selectedDrive}
          fetchAllDrives={fetchAllDrives}
          buildings={buildings}
        />
      )}
    </div>
  );
}

export default DriveAdmin;
