import { useContext, useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { fetchUserData } from "../api/Auth";

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const { user, setUser, isAuthenticated, setIsAuthenticated } =
    useContext(AuthContext);
  const { ilaqaAccessToken: accessToken, ilaqaPhNumber: number } =
    localStorage.getItem("ilaqaAuthDetails")
      ? JSON.parse(localStorage.getItem("ilaqaAuthDetails"))
      : {};

  useEffect(() => {
    const loadUser = async () => {
      setLoading(true);
      try {
        const userData = await fetchUserData(number, accessToken);
        setUser(userData);
        if (userData) {
          setIsAuthenticated(true);
          if (userData.role === "admin" || userData.role === "superadmin") {
            if (
              location.pathname === "/signin" ||
              location.pathname === "/dashboard"
            ) {
              navigate("/dashboard");
            }
          } else {
            if (userData.fullName === undefined) {
              navigate("/register");
            } else {
              navigate("/profile");
            }
          }
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error("Failed to fetch user data:", error);
        setIsAuthenticated(false);
      } finally {
        setLoading(false);
      }
    };

    if (accessToken && number) {
      loadUser();
    } else {
      setLoading(false);
      setIsAuthenticated(false);
    }
  }, [number, accessToken, setUser, setIsAuthenticated]);

  if (loading) return null;
  if (!isAuthenticated) {
    return <Navigate to="/signin" />;
  }

  return children;
};

export default ProtectedRoute;
