import React from "react";
import "./Hero.css";
import Services from "../Services/Services";
import Modal from "../WhatsappLinkModal/Modal";
import { useNavigate } from "react-router-dom";
import Whatsapp from "../../assets/images/Whatsapp.png";
import heroImage from "../../assets/images/Group 443.svg";
import Drive from "../Drives/Drive";
const Hero = ({ isLink, setIsLink }) => {
  const navigate = useNavigate();
  return (
    <>
      <section className="hero">
        <div className="hero-text-with-image">
          <div className="hero-text">
            <div className="hero-text-wrapper">
              <div className="ilaqa-wrapper not-for-desktop">
                <h1 className="ilaqa">iLaqa</h1>
                <p className="ilaqa-tag">
                  Your neighborhood trusted home service
                </p>
              </div>
              <Drive />
              <h2 className="hero-heading">
                Your
                <span className="one-stop"> One stop</span> solution to all
                services
              </h2>
              <ul>
                <li>Verified and recommended vendors</li>
                <li>Talk to a real person for queries and booking </li>

                <li>Post service follow ups and refunds</li>
                <li>
                  30+ services, documentation, laptop, mesh, grills, vehicle,cab
                </li>
              </ul>

              <div className="desktop-hero-buttons">
                <button
                  className="heroListButton"
                  onClick={() => {
                    navigate("/services");
                    console.log("jee");
                  }}
                >
                  List of Services
                </button>
                <button
                  className="joinButton"
                  style={{
                    display: "flex",
                    gap: "10px",
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    setIsLink(true);
                  }}
                >
                  <span>
                    Welcome to your{" "}
                    <span className="one-stop">{` iLaqa`} </span>
                  </span>
                  <img
                    src={Whatsapp}
                    className="whatsapp"
                    style={{ width: "36px", height: "36px" }}
                  ></img>
                </button>
              </div>
            </div>
          </div>
          <img src={heroImage} className="hero-right-image"></img>
        </div>
        <div>
          <Services />
        </div>

        <div className="parentdiv" style={{ marginBlock: "20px" }}>
          <div
            className="txtcolumn"
            style={{
              display: "flex",
              alignItems: "baseline",
              padding: "1rem 0rem 0rem",
            }}
          >
            <p className="List-of-Services-before-btn">List of Services</p>
            <button
              onClick={() => {
                navigate("/services");
              }}
              className="heroListButton"
              style={{
                padding: "4px 0px",
                flex: 1,
                margin: "0",
              }}
            >
              View
            </button>
          </div>

          <div
            className="buttoncolumn"
            style={{
              display: "flex",
              alignItems: "baseline",
              padding: "1rem 0rem",
            }}
          >
            <p className="Welcome-to-your-iLaqa-before-btn">
              Welcome to <span style={{ color: "orange" }}> your </span>iLaqa
            </p>

            <button
              onClick={() => {
                setIsLink(true);
              }}
              className="joinButton"
              style={{ padding: "6px 0px", flex: 1, margin: "0" }}
            >
              Join
              <img className="whatsapp" alt="joinbttn" src={Whatsapp} />
            </button>
          </div>
        </div>
      </section>

      {isLink && <Modal setIsLink={setIsLink} isLink={isLink}></Modal>}
    </>
  );
};

export default Hero;
