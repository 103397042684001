import React from "react";
import "./Testimonials.css";
import N1 from "../../assets/images/1.svg";
import N2 from "../../assets/images/2.svg";
import N3 from "../../assets/images/3.svg";
import N4 from "../../assets/images/4.svg";
import N5 from "../../assets/images/5.svg";
import N6 from "../../assets/images/6.svg";
import N7 from "../../assets/images/7.svg";
import q1 from "../../assets/images/qt1.png";
import q2 from "../../assets/images/qt2.png";
import q3 from "../../assets/images/qt3.png";
import q4 from "../../assets/images/qt4.png";
import q5 from "../../assets/images/qt5.png";
import q6 from "../../assets/images/qt6.png";
import q7 from "../../assets/images/qt7.png";
import Neighbors from "../../assets/images/Neighbors.png";
import { Carousel } from "react-bootstrap";

const Testimonials = () => {
  return (
    <>
      <section id="testimonials" className="p-4">
        <div className="testimonial-wrapper">
          <h2 className="testimonial-heading" style={{ paddingInline: 0 }}>
            What our Neighbors say about{" "}
            <span className="highlight">iLaqa</span>
          </h2>
          <Carousel
            controls={false}
            style={{ height: "150px", padding: "5px" }}
          >
            <Carousel.Item>
              <div
                className="d-flex img-txt"
                style={{
                  paddingBlock: "10px",
                  display: "flex",
                  gap: "10px",
                  paddingInline: "5px",
                }}
              >
                <div className="cst-slide-img">
                  <img src={N1} alt="person" className="resized-image" />
                </div>
                <div className="cst-slide-info">
                  <img
                    src={q1}
                    alt="quote"
                    className="img-fluid  position-relative"
                  ></img>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div
                className="d-flex img-txt"
                style={{
                  paddingBlock: "10px",
                  display: "flex",
                  gap: "10px",
                  paddingInline: "5px",
                }}
              >
                <div className="cst-slide-img">
                  <div className="position-relative h-100">
                    <img src={N2} alt="person" className="resized-image" />
                  </div>
                </div>
                <div className="cst-slide-info">
                  <img
                    src={q2}
                    alt="quote"
                    className="img-fluid  position-relative"
                  ></img>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div
                className="d-flex img-txt"
                style={{
                  paddingBlock: "10px",
                  display: "flex",
                  gap: "10px",
                  paddingInline: "5px",
                }}
              >
                <div className="cst-slide-img">
                  <div className="position-relative h-100">
                    <img src={N3} alt="person" className="resized-image" />
                  </div>
                </div>
                <div className="cst-slide-info">
                  <img
                    src={q3}
                    alt="quote"
                    className="img-fluid  position-relative"
                  ></img>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div
                className="d-flex img-txt"
                style={{
                  paddingBlock: "10px",
                  display: "flex",
                  gap: "10px",
                  paddingInline: "5px",
                }}
              >
                <div className="cst-slide-img">
                  <div className="position-relative h-100">
                    <img src={N4} alt="person" className="resized-image" />
                  </div>
                </div>
                <div className="cst-slide-info">
                  <img
                    src={q4}
                    alt="quote"
                    className="img-fluid  position-relative"
                  ></img>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div
                className="d-flex img-txt"
                style={{
                  paddingBlock: "10px",
                  display: "flex",
                  gap: "10px",
                  paddingInline: "5px",
                }}
              >
                <div className="cst-slide-img">
                  <div className="position-relative h-100">
                    <img src={N5} alt="person" className="resized-image" />
                  </div>
                </div>
                <div className="cst-slide-info">
                  <img
                    src={q5}
                    alt="quote"
                    className="img-fluid  position-relative"
                  ></img>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div
                className="d-flex img-txt "
                style={{
                  paddingBlock: "10px",
                  display: "flex",
                  gap: "10px",
                  paddingInline: "5px",
                }}
              >
                <div className="cst-slide-img">
                  <div className="position-relative h-100">
                    <img src={N6} alt="person" className="resized-image" />
                  </div>
                </div>
                <div className="cst-slide-info">
                  <img
                    src={q6}
                    alt="quote"
                    className="img-fluid  position-relative"
                  ></img>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div
                className="d-flex img-txt"
                style={{
                  paddingBlock: "10px",
                  display: "flex",
                  gap: "10px",
                  paddingInline: "5px",
                }}
              >
                <div className="cst-slide-img">
                  <div className="position-relative h-100">
                    <img src={N7} alt="person" className="resized-image" />
                  </div>
                </div>
                <div className="cst-slide-info">
                  <img
                    src={q7}
                    alt="quote"
                    className="img-fluid position-relative "
                  ></img>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>

          <div className="neighbours">
            <img src={Neighbors} className="img-fluid me-2" />
            <p> Connected neighbors</p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Testimonials;
