import React, { useContext } from "react";

import { Outlet, useLocation, useNavigate } from "react-router-dom";
import logo from "../assets/images/logo.png";
import cross from "../assets/images/Cross Icon.png";
import { AuthContext } from "../context/AuthContext";
import Header from "../components/Header/Header";
function AuthHeaderLayout() {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useContext(AuthContext);
  return (
    <div
      style={{
        width: "100vw",
      }}
    >
      <div className="desktop-version">
        <Header />
      </div>
      <div className="about-us-header not-for-desktop">
        <img
          src={logo}
          alt=""
          style={{ width: "60px", height: "60px" }}
          onClick={() => {
            navigate("/");
          }}
        ></img>
        <img
          src={cross}
          alt=""
          style={{ width: "24px", height: "24px", cursor: "pointer" }}
          onClick={() => {
            if (location.pathname === "/voucher") {
              navigate("/profile");
            } else {
              navigate("/");
            }
          }}
        ></img>
      </div>
      <Outlet></Outlet>
    </div>
  );
}

export default AuthHeaderLayout;
