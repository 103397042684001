import { URL } from "../env";
import { GetAccessToken } from "./api";
// utils/auth.js

// if (!accessToken) {
//   throw new Error("No access token found. Please log in.");
// }
export const fetchUserData = async (number, token) => {
  const response = await fetch(`${URL}/users/get/${number}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  if (response.status === 401) {
    let res = await GetAccessToken();
    const resData = await res.json();
    let storedAuthDetails =
      JSON.parse(localStorage.getItem("ilaqaAuthDetails")) || {};

    storedAuthDetails.ilaqaAccessToken =
      resData.AuthenticationResult?.AccessToken;

    localStorage.setItem("ilaqaAuthDetails", JSON.stringify(storedAuthDetails));
    const response = await fetch(`${URL}/users/get/${number}`, {
      // Replace with your API endpoint
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${resData.AuthenticationResult?.AccessToken}`,
      },
    });
    if (
      response.status === 400 &&
      res.message === "Refresh Token has expired"
    ) {
      return null;
    }
    const userData = await response.json();

    return userData;
  }

  const userData = await response.json();

  return userData;
};
