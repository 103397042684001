import React from "react";
import logo from "../../assets/images/logo.png";
import cross from "../../assets/images/Cross Icon.png";

import "./Headeradmin.css";
import { useLocation, useNavigate } from "react-router-dom";

function HeaderAdmin() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div
      className="header-container"
      style={{
        width: "100vw",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingInline: "18px",
        paddingBlock: "20px",
      }}
    >
      <img
        src={logo}
        style={{ paddingTop: "10px", cursor: "pointer" }}
        className="logoa"
        alt="Logo"
        onClick={() => {
          if (location.pathname === "/dashboard") {
            navigate("/");
          } else {
            navigate("/dashboard");
          }
        }}
      />{" "}
      <img
        src={cross}
        style={{ width: "24px", height: "24px", cursor: "pointer" }}
        onClick={() => {
          if (location.pathname === "/dashboard/referral/redemptionrequests") {
            navigate("/dashboard/referral");
          } else if (location.pathname === "/dashboard") {
            navigate("/");
          } else {
            navigate(-1);
          }
        }}
      ></img>
    </div>
  );
}

export default HeaderAdmin;
